import { useState } from "react";
import { Button } from "react-bootstrap";
const Counter = ({register,index,setValue})=>{
  const [count, setCount] = useState(0);
  const increment = () => {
    setCount(prev=> {
      prev = ++prev
      setValue(`dishes[${index}][total_servings]`,prev)
      return prev;
    });
    
  }

  const decrement = () => {
    setCount((prev)=> {
      prev = --prev
      setValue(`dishes[${index}][total_servings]`,prev)
      return prev;
    })
  }

  return (
    <div className="d-flex align-items-center" style={{width:"35px"}}>
      <button onClick={decrement} className="btn btn-outline-success-light border btn-outline-success ">-</button>
      <span className="m-2">
      {' '}{count < 10 ? `0${count}` : count}{' '}
      </span>
      <button onClick={increment} type="button" className="btn border btn-outline-success-light btn-outline-success">+</button>
    </div>
  );

}


export default Counter