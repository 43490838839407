import React, { useState } from 'react';
import axios from "axios";
import * as constant from '../constant';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import VerificationPopup from '../components/VerificationPopup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


function ForgotPasswordPage() {
   const [modalShow, setModalShow] = React.useState(false);
   const schema = yup.object({
      email: yup.string()
         .email("Enter valid email")
         .required("Email is required"),
   });
   const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
   });

   const onSubmit = async (formData, e) => {
      try {
         const response = await axios.post(constant.VENDOR_API_URL + "/forgetPassword", formData);
         if (response.data.status) {
            setModalShow(true)

         } else {
            toast.error(response.data.message, {
               position: toast.POSITION.TOP_RIGHT
            });
         }
      }
      catch (error) {
         toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
         });

      }
   }

   return (
      <>
         <div className="wraper-inner">
            <section className="accounts-page">
               <div className="container-fluid">
                  <div className="accounts-page-box row">
                     <div className="col-md-7 accounts-page-box-left">
                        <Link to="/"> <figure className="pt-4"><img src="images/Eats_logo.png" /></figure></Link>
                        <div className="accounts-page-box-inner-L">
                           <div className="common-bg-form">
                              <div className="top-from-headning text-center mb-5">
                                 <h3>Forgot password</h3>
                                 <p>Please enter the registered email to receive link</p>
                              </div>

                              <form onSubmit={handleSubmit(onSubmit)} className="form-box login-form">
                                 <ToastContainer />
                                 <div className="form-group mb-4">
                                    <label className="form-label">Email</label>
                                    <input type="text" placeholder="Michael@abc.com" className="form-control" {...register("email")} />
                                    {errors.email && <span className="validation_error">{errors.email?.message}</span>}
                                 </div>
                                 <div className="btn-box text-center mt-4"><button type="submit" className="btn btn-primary mt-2" >Continue</button></div>
                              </form>
                           </div>
                        </div>
                        <figure className="shape-1"><img src="images/shape-img.png" /></figure>
                     </div>
                     <div className="accounts-page-box-right col-md-5">
                        <a href="index.php">
                           <figure><img src="images/Eats_logo-w.png" /></figure>
                        </a>
                        <div className="accounts-page-box-inner-R">
                           <div className="account-content">
                              <h3>Turn Your Passion Into Profit</h3>
                              <p>Treat the world to what deliciousness you’re whipping up! We enthusiastically invite the entire food and beverage community, from chefs, farms, supermarkets, and restaurants to food trucks, hot dog stands, even artisanal pickle makers, and more! We provide you with all the tools you need to operate a thriving business, tailored to your unique vision.  Join the feast! </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>

         <VerificationPopup
            show={modalShow}
            onHide={() => setModalShow(false)}
         />
      </>
   );

}

export default ForgotPasswordPage;
