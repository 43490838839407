import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../store/slice/dashboardHeaderSlice";
 
function HelpCenter() {
    const dispatch = useDispatch();
    dispatch(setHeaderTitle("Help Center"));
    return (
        <div>HelpCenter</div>
    )
}

export default HelpCenter