import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as constant from "../../constant";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../store/slice/dashboardHeaderSlice';

function DishList() {

    
  const dispatch = useDispatch();
  dispatch(setHeaderTitle("My Menu"));

  const [dishList, setDishList] = useState([]);
  const [setList, setSetList] = useState([]);
  const [menuDates, setMenuDate] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const getInitialDates = () => {
    var listDate = [];
    var start_date = new Date();
    for (var i = 0; i < 12; i++) {
      const date = new Date(start_date);
      date.setDate(date.getDate() + 1);
      start_date = date;
      listDate.push(date.toISOString());
    }
    setMenuDate([...listDate]);
  };

  // const isoToDate = (date) => {
  //   date = new Date(date);
  //   return date.getDate() + "-" + date.getMonth().toString().padStart(2, "0") + "-" + date.getFullYear();
  // };

  // function addDate() {
  //   if (menuDates.length <= 30) {
  //     const last_added_date = menuDates[menuDates.length - 1];

  //     const date = new Date(last_added_date);
  //     date.setDate(date.getDate() + 1);

  //     setMenuDate([...menuDates, date.toISOString()]);
  //   }
  // }
  const getDishData = async () => {
    let token = localStorage.getItem("token");
    const headers = { Authorization: "Bearer " + token };
    const response = await axios.get(constant.VENDOR_API_URL + "/getVendorDishes", { headers });

    if (response.data.status) {
      setDishList(response.data.data);
      if (response.data.data.length > 0) {
        setDataAvailable(true);
      } else {
        setDataAvailable(false);
      }
    } else {
      toast.error(response.data.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteDish = async (dish_id) => {
    try {
      let token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(constant.VENDOR_API_URL + "/deleteDish", { dish_id: dish_id }, { headers });
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const deleteSet = async (set_id) => {
    try {
      let token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(constant.VENDOR_API_URL + "/deleteSet", { set_id: set_id }, { headers });
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const handleDeleteClick = (dishId) => {
    const confirmed = window.confirm("Are you sure you want to delete this Dish?");
    if (confirmed) {
      deleteDish(dishId);
    }
  };
  const handleSetDeleteClick = (setId) => {
    const confirmed = window.confirm("Are you sure you want to delete this Set?");
    if (confirmed) {
      deleteSet(setId);
    }
  };

  // const selectAllDishes = () => {
  //   setSelectAll(!selectAll);
  //   const updatedDishList = dishList.map((item) => ({ ...item, selected: !selectAll }));
  //   setDishList(updatedDishList);
  // };

  // const toggleSelectDish = (index) => {
  //   const updatedDishList = [...dishList];
  //   updatedDishList[index].selected = !updatedDishList[index].selected;
  //   setDishList(updatedDishList);
  // };
  useEffect(() => {
    getDishData();

    getInitialDates();
  }, [refresh]);

  return (
    <div class="dash-side-R-body">
      <div class="navs-tabs">
        {/* <div class=" tabbing-adds-box">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="tab-001"
                data-bs-toggle="tab"
                data-bs-target="#tab-01"
                type="button"
                role="tab"
                aria-controls="tab"
                aria-selected="true">
                All
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="tab-003"
                data-bs-toggle="tab"
                data-bs-target="#tab-03"
                type="button"
                role="tab"
                aria-controls="tab"
                aria-selected="false"
                tabindex="-1">
                Today
              </button>
            </li>

            {menuDates.map((date) => {
              return (
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="tab-003"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-03"
                    type="button"
                    role="tab"
                    aria-controls="tab"
                    aria-selected="false"
                    tabindex="-1">
                    {isoToDate(date)}
                  </button>
                </li>
              );
            })}
          </ul>
          <a class="addition-icon action-box" onClick={addDate}>
            <i class="ri-add-line"></i>
          </a>
        </div> */}
        <div class="tab-content " id="myTabContent">
          <div class="tab-pane fade show active" id="tab-01" role="tabpanel" aria-labelledby="tab-001" tabindex="0">
            <div class="all-info mt-4">
              <div class="top-btn-box justify-content-end d-flex mb-4">
              <Link to="add-occasional" class="btn btn-outline me-3">
                  <i class="ri-add-line"></i> Add New Ocassional Dish
              </Link>
                <Link to="add-set" class="btn btn-outline me-3">
                  <i class="ri-add-line"></i> Add New Set
                </Link>
                <Link to="add-dish" class="btn btn-primary">
                  <i class="ri-add-line"></i> Add New{" "}
                </Link>
              </div>

              <div class="row row-cols-4 slides-show-border-box">
                {dishList.map((item, index) => {

                    let edit_link=`edit-dish/` + item._id;
                    let detail_link=`dish-detail/` + item._id;
                 

                    if(item.dish_type =='set'){
                      edit_link=`edit-set/` + item._id;
                      detail_link=`set-detail/` + item._id;
                      
                    }

                    if(item.dish_type =='occasional'){
                      edit_link=`edit-occasional/` + item._id;
                      detail_link=`occasional-detail/` + item._id;
                     
                    }


                  return (
                    <div class="cols" key={index}>
                      <div class="slides-show-border-inner">
                        <div class="d-flex action-main action-black">
                          
                          <Link to={edit_link} class="action-box">
                          <i class="ri-edit-box-line"></i>
                          </Link>

                          <a class="action-box delete-action ms-2">
                            <i class="ri-delete-bin-line" onClick={() => handleDeleteClick(item._id)}></i>
                          </a>
                        </div>
                        <Link to={detail_link}>
                          <figure>
                            <img src={constant.API_URL + "/" + item.dish_image} />
                          </figure>
                        </Link>
                        <div class="menu-head-title d-flex justify-content-between align-items-center mb-2">
                          <h4>{item.name}</h4>
                          <span class="price-text"> {`$${item?.food_class[0]?.price}`}</span>
                        </div>
                        <ul class="menu-info">
                          <li>
                            {" "}
                            <span>
                              <b>Food Class: </b> {item?.food_class[0]?.class}
                            </span>
                          </li>

                          {item?.food_type?.map((item, index) => {
                            return (
                              <li>
                                <b>Food Type: </b>
                                {item.name}
                              </li>
                            );
                          })}
                          {item.spice_level &&
                            <li>
                            <b> Spice Level: </b>
                            <span>{item.spice_level}</span>
                          </li>
                          }
                          

                        </ul>
                      </div>
                    </div>
                  );
                })}
                
              </div>
            </div>
          </div>

          {/* <div class="tab-pane fade " id="tab-03" role="tabpanel" aria-labelledby="tab-003" tabindex="0">
            <div class="date-menu mt-4">
              <div class="all-info mt-4">
                <div class="top-btn-box justify-content-between d-flex align-items-center mb-4">
                  {dataAvailable && (
                    <label class="coustom-checkbox me-4 sm-check-box">
                      <input type="checkbox" onChange={selectAllDishes} />
                      <span class="checkmark"></span>
                      <p>Select All</p>
                    </label>
                  )}
                  {dataAvailable && (
                    <Link to="add-menu-detail" class="btn btn-primary">
                      Add Menu Details{" "}
                    </Link>
                  )}
                </div>
                <div class="row row-cols-4 slides-show-border-box">
                  {dishList.map((item, index) => {
                    return (
                      <div class="cols ">
                        <div class="slides-show-border-inner">
                          <label class="coustom-checkbox me-4 sm-check-box custom-select">
                            <input type="checkbox" checked={item.selected} onChange={() => toggleSelectDish(index)} />
                            <span class="checkmark"></span>
                          </label>
                          <figure>
                            <img src={constant.API_URL + "/" + item.dish_image} />
                          </figure>
                          <div class="menu-head-title d-flex justify-content-between align-items-center mb-2">
                            <h4>{item.name}</h4>
                            <span class="price-text"> {`$${item?.food_class[0]?.price}`}</span>
                          </div>
                          <ul class="menu-info">
                            <li>
                              {" "}
                              <span>
                                <b>Food Class: </b> {item?.food_class[0]?.class}
                              </span>
                            </li>

                            {item?.food_type?.map((item, index) => {
                              return (
                                <li>
                                  <b>Food Type: </b>
                                  {item.name}
                                </li>
                              );
                            })}
                            <li>
                              <b> Spice Level: </b>
                              <span>{item.spice_level}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default DishList;
