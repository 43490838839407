import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

function StepBar({ step }) {

   return (

      <div class="step-process">
         <ul class="progessbar-list d-flex">

            <li class={`${step == 1 && "active"||step == 2 && "active active-check" || step == 3 && "active active-check" || step == 4 && "active active-check"}`}>
               <span>01</span>
               <h6>Signup </h6>
            </li>

            <li class={`${step == 2 && "active"||step == 3 && "active active-check"   || step == 4 && "active active-check"}`}>
               <span>02</span>
               <h6>Vendor Details</h6>

            </li>
            <li class={`${step == 3 && "active"||step == 4 && "active active-check"}`}>
               <span>03</span>
               <h6>Upload Documents</h6>
            </li>
            <li class={`${step == 4 && "active"}`}>
               <span>04</span>
               <h6>Payment Information</h6>
            </li>
         </ul>
      </div>

   );

}

export default StepBar;
