import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as constant from '../constant';
const parse = require('html-react-parser');


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2
};

function SimpleSlider({ testimonials }) {
  return (
    <div>

      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div className="item" key={index}>
            <div className="slider-box-content">
              <p>{parse(testimonial.comment)}</p>
            </div>
            <div className="slider-box-bottom d-flex align-items-center">
              <figure><img src={constant.API_URL + "/" + testimonial.image} /></figure>
              <h3 className="user-title">{testimonial.name}</h3>
            </div>
          </div>

        ))}

      </Slider>
    </div>
  );
}



export default SimpleSlider;