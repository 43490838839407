import React, { Component, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { login, logout } from "../store/slice/authSlice";

function Sidebar() {

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const logoutHandler = () => {

      const confirm = window.confirm("Are you sure to logout?");
      if (confirm) {
         localStorage.removeItem('token');
         dispatch(logout());
         navigate('/login');
      }

   }


   return (
      <div className="side-bar-L">
         <div className="sidebar-head">
            <figure><a href="index"><img src="/images/Eats_logo.png" /></a></figure>
         </div>
         <div className="sidebar-body">
            <ul className="sidebar-link-list">
               <li >
                  <NavLink to="/dashboard">
                     <figure><i className="ri-dashboard-line"></i></figure>
                     Dashboard
                  </NavLink>
               </li>
               {/* <li >
                  <Link to="slideshow-listings">
                     <figure><i className="ri-slideshow-3-line"></i></figure>
                     Slideshow Listings
                  </Link>
               </li>
               <li>
                  <Link to="orders">
                     <figure><i className="ri-shopping-cart-2-line"></i></figure>
                     My Orders
                  </Link>
               </li> */}
               <li>
                  <NavLink to="menu">
                     <figure><i className="ri-file-list-3-line"></i></figure>
                     My Menu
                  </NavLink>
               </li>
               {/* <li>
                  <Link to="foodtype">
                     <figure><i className="ri-restaurant-2-line"></i></figure>
                     Food Type
                  </Link>
               </li>
               <li>
                  <Link to="food-communities">
                     <figure> <i className="ri-layout-2-line"></i></figure>
                     Food Communities
                  </Link>
               </li>
               <li>
                  <Link to="earnings">
                     <figure> <i className="ri-hand-coin-line"></i></figure>
                     My Earnings
                  </Link>
               </li>
               <li>
                  <Link to="reviews">
                     <figure> <i className="ri-star-line"></i></figure>
                     My Reviews
                  </Link>
               </li>
               <li>
                  <Link to="help-center">
                     <figure><i className="ri-question-line"></i></figure>
                     Help Center
                  </Link>
               </li>
               <li>
                  <Link to="settings">
                     <figure><i className="ri-settings-2-line"></i></figure>
                     Settings
                  </Link>
               </li> */}

               <li>
                  <a onClick={logoutHandler}>
                     <figure><i className="ri-logout-box-r-line"></i></figure>
                     Logout
                  </a>
               </li>

            </ul>
         </div>
      </div>

   );
}



export default Sidebar;