import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constant from "../../constant";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Link, redirect, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";

function Step1({ setStep }) {
  const navigate = useNavigate();
  const [passwordshow, setPasswordshow] = useState({
    password: false,
    confirm_password: false,
  });
  const [modalShow, setModalShow] = React.useState(false);
  function Step1Popup(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
        <div className="modal-head d-flex align-items-center justify-content-center">
          <h1 className="modal-title" id="exampleModalLabel">
            First Step Completed.
          </h1>
        </div>
        <div className="modal-body">
          <p className="text-center">Yay!! You have completed the first step.</p>
        </div>
        <div className="bottom-modal-footer text-center mt-3">
          <button
            type="button"
            onClick={() => {
              {
                props.onHide();
              }
              {
                props.step();
              }
            }}
            className="btn btn-primary">
            Continue
          </button>
        </div>
      </Modal>
    );
  }

  const schema = yup.object().shape({
    first_name: yup.string().required("First Name is required").min(3, "Please enter a name more than 3 character"),
    last_name: yup.string().required("Last Name is required").min(2, "Please enter a name more than 2 character"),
    phone: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Phone is required"),
    email: yup.string().email("Enter valid email").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, {
        message: "Passwords should be atleast 8 characters with uppercase letters, lowercase letters and at least one number.",
      }),

    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    term: yup.boolean().oneOf([true], "Terms And Conditions is required"),
  });

  const passwordHandler = (type) => {
    if (type == "password") {
      setPasswordshow({ ...passwordshow, password: !passwordshow.password });
    }
    if (type == "confirm_password") {
      setPasswordshow({ ...passwordshow, confirm_password: !passwordshow.confirm_password });
    }
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);




  return (
    <>
      <div>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            password: "",
            confirm_password: "",
            term: false,
          }}
          validationSchema={schema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async (values) => {
            try {
              const response = await axios.post(constant.VENDOR_API_URL + "/signUp", values);
              if (response.status === 200) {
                setModalShow(true);
                localStorage.setItem("token", response.data.data.token);
                
              } else {
                toast.error(response.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } catch (error) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (


            <Form className="form-box login-form">



              <div className="form-box signup-form col-md-8 m-auto">
                <div className="row">
                  <div className="col-md-6">

                

                    <div className="form-group mb-4">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        placeholder="Enter First Name"
                        className="form-control"
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name.replace(/ +/g, " ")}
                        maxLength={20}
                      />
                      <span class="validation-error">{errors.first_name && touched.first_name && errors.first_name}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        className="form-control"
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name.replace(/ +/g, " ")}
                        maxLength={20}
                      />
                      <span class="validation-error">{errors.last_name && touched.last_name && errors.last_name}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Email Address</label>
                      <input
                        type="text"
                        placeholder="Enter Email Address"
                        className="form-control"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <span class="validation-error">{errors.email && touched.email && errors.email}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Mobile Number</label>
                      <input
                        type="text"
                        placeholder="Enter Mobile number"
                        className="form-control"
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                      <span class="validation-error">{errors.phone && touched.phone && errors.phone}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Password</label>
                      <div className="with-icon">
                        <input
                          type={passwordshow.password ? "text" : "password"}
                          placeholder="Enter Password"
                          className="form-control"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <div className="icon-box">
                          <i
                            className={passwordshow.password ? "ri-eye-line" : "ri-eye-off-line"}
                            onClick={() => passwordHandler("password")}></i>
                        </div>
                        <span class="validation-error">{errors.password && touched.password && errors.password}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-4">
                      <label className="form-label">Confirm Password</label>
                      <div className="with-icon">
                        <input
                          type={passwordshow.confirm_password ? "text" : "password"}
                          placeholder="Confirm password"
                          className="form-control"
                          name="confirm_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirm_password}
                        />
                        <div className="icon-box">
                          <i
                            className={passwordshow.confirm_password ? "ri-eye-line" : "ri-eye-off-line"}
                            onClick={() => passwordHandler("confirm_password")}></i>
                        </div>
                        <span class="validation-error">
                          {errors.confirm_password && touched.confirm_password && errors.confirm_password}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <label class="coustom-checkbox " style={{float: "left"}}>
                      <input type="checkbox" name="term" onChange={handleChange} onBlur={handleBlur} value={values.term} />

                      <span class="checkmark"></span>
                      </label>
                      <a class="hyper" href="/term-condition">Accept Terms And Conditions</a>
                      
                   
                    <span class="validation-error">{errors.term && touched.term && errors.term}</span>
                  </div>
                </div>
              </div>
              <div className="btn-box text-center mt-4">
                <button type="submit" className="btn btn-primary mt-2">
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Step1Popup
        show={modalShow}
        onHide={() => setModalShow(false)}
        step={() => {
          setStep(2);
        }}
      />
    </>
  );
}

export default Step1;
