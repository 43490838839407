import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constant from "../../constant";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../../store/slice/dashboardHeaderSlice";

function SetDetail() {
  const dispatch = useDispatch();
  dispatch(setHeaderTitle("Set Detail"));

  const [data, setData] = useState([]);
  const [foodClass, setFoodClass] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const deleteSet = async (set_id) => {
    try {
      let token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(constant.VENDOR_API_URL + "/deleteSet", { set_id: set_id }, { headers });
      setRefresh(!refresh);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const handleSetDeleteClick = (setId) => {
    const confirmed = window.confirm("Are you sure you want to delete this Set?");
    if (confirmed) {
      deleteSet(setId);
    }
  };
  useEffect(() => {
    fetchData();
  }, [refresh]);
  const fetchData = async () => {
    try {
      let token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(constant.VENDOR_API_URL + "/getSetDetails", { params: { id: id }, headers });

      setData(response.data.data);
      setFoodClass(JSON.parse(response.data.data.food_class));

      setPageLoader(false);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <div class="dash-main-R detail-page">
      {pageLoader ? null : (
        <div class="dash-side-R-head d-flex justify-content-between align-items-center">
          <div class="dash-side-R-head-left">
            <h2>Set Detail</h2>
          </div>
          <div class="dash-side-R-head-right d-flex align-items-center">
            <div class="dropdown profile-drop">
              <button
                class=" dropdown-toggle d-flex align-items-center"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <figure>
                  {" "}
                  <img src="images/profile-img.png" />
                </figure>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a data-bs-toggle="modal" data-bs-target="#logout" class="dropdown-item" href="#">
                    <i class="ri-logout-circle-line"></i> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div class="dash-side-R-body">
        <div class="row order-detail-list-box mt-4">
          <div class="col-md-4">
            <figure class="dish-img">
              <img src={constant.BACKEND_URL + data.dish_image} />
            </figure>
            <div class="price-info">
              <h4>{data.name}</h4>
              <ul>
                {data?.food_class?.map((item, index) => {
                  return (
                    <>
                      <li>
                        <span>
                          <b>Food Class: </b> {item?.class}
                        </span>
                        <span>
                          <b>Price: </b> {`$${item?.price}`}
                        </span>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>

            <ul class="order-detail-list">
              <li class="df">
                <b>Cuisine: &nbsp;</b>{" "}
                {data?.cuisine?.map((item, index) => {
                  if (data?.cuisine.length - 1 == index) {
                    return item.name;
                  } else {
                    return item.name + ", ";
                  }
                })}
              </li>

              <li style={{ borderBottom: "none" }}>
                <b>Contents: &nbsp;</b>
              </li>
              <li>
                {data?.set_contents?.map((item, index) => {
                  return (
                    <span class="order-detail-list order-group-list">
                      <ul class="order-detail-list order-group-list">
                        <li>
                          <b>Group {index + 1}: &nbsp;</b>
                          {item?.group_name}
                        </li>
                        <li>
                          <b>Name Of Communities : &nbsp;</b>
                          {item?.communities.map((communities, index) => {
                            return <span> {communities.name}, </span>;
                          })}
                        </li>
                        <li>
                          <b>Number Of Items Needed To Be Chosen : &nbsp;</b>
                          <span>{item?.item_number}</span>
                        </li>
                      </ul>
                    </span>
                  );
                })}
              </li>
            </ul>

            <hr class="divider-accordion" />
            <ul class="order-detail-list order-group-list">
              <li >
                <b>Description : </b> &nbsp; <br></br>
                 <span> {data?.description}</span>
              </li>
            </ul>
            <div class="btn-group-box mt-4">
              <Link to={`../edit-set/${id}`} class="btn btn-primary">
                Edit
              </Link>
              <Link class="btn btn-danger ms-2" onClick={() => handleSetDeleteClick(data._id)}>
                Delete
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SetDetail;
