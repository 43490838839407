import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Navigate,
  useRoutes,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Dashboard from "./pages/Dashboard";
import DashboardLayout from "./layouts/DashboardLayout";
import HomePage from "./pages/HomePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermCondtionPage from "./pages/TermCondtionPage";
import HelpPage from "./pages/HelpPage";
import Counter from "./components/Counter";
import { useSelector } from "react-redux";
import FrontLayout from "./layouts/FrontLayout";
import SignupForm from "./pages/sign_up/SignupForm";
import Settings from "./pages/Settings";
import HelpCenter from "./pages/HelpCenter";
import MyReviews from "./pages/MyReviews";
import MyEarnings from "./pages/MyEarnings";
import FoodType from "./pages/FoodType";
import FoodCommunities from "./pages/FoodCommunities";
import DishList from "./pages/Menu/DishList";
import AddSet from "./pages/Menu/AddSet";
import AddDish from "./pages/Menu/AddDish";
import AddOcassional from "./pages/Menu/AddOcassional";
import DishDetail from "./pages/Menu/dishDetail";
import SetDetail from "./pages/Menu/setDetail";
import AddMenuDetail from "./pages/Menu/AddMenuDetail";
import EditDish from "./pages/Menu/EditDish";
import EditSet from "./pages/Menu/EditSet";
import EditOccasional from "./pages/Menu/EditOccasional";
import OcassionalDetail from "./pages/Menu/OcassionalDetail";



function App() {


  const auth_token = useSelector((state) => state.auth.token);

  let routing = useRoutes([
    {
      path: "/",
      element: <FrontLayout />,
      children: [
        { path: "/", element: <HomePage /> },
        { path: "/privacy-policy", element: <PrivacyPolicyPage /> },
        { path: "/term-condition", element: <TermCondtionPage /> },
        { path: "/help", element: <HelpPage /> },
      ],
    },
    {
      path: "/dashboard",
      element: auth_token ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: "",
          element: <Dashboard />,
        },

        {
          path: "menu",
          children: [
            {
              path: "",
              element: <DishList />,
            },
            {
              path: "add-dish",
              element: <AddDish />,
            },

            {
              path: "add-occasional",
              element: <AddOcassional/>,
            },

            {
              path: "add-set",
              element: <AddSet />,
            },
            {
              path: "dish-detail/:id",
              element: <DishDetail/>,
            },
            {
              path: "set-detail/:id",
              element: <SetDetail/>,
            },
            {
              path: "occasional-detail/:id",
              element: <OcassionalDetail/>,
            },
            
            {
              path: "add-menu-detail",
              element: <AddMenuDetail />,
            },
            {
              path: "edit-dish/:id",
              element: <EditDish />,
            },
            {
              path: "edit-set/:id",
              element: <EditSet />,
            },
            {
              path: "edit-occasional/:id",
              element: <EditOccasional />,
            },
          ],
        },

      
      

        {
          path: "foodtype",
          element: <FoodType />,
        },
        {
          path: "earnings",
          element: <MyEarnings />,
        },
        {
          path: "food-communities",
          element: <FoodCommunities />,
        },
        {
          path: "reviews",
          element: <MyReviews />,
        },
        {
          path: "help-center",
          element: <HelpCenter />,
        },
        {
          path: "settings",
          element: <Settings />,
        }
      ],
    },
    {path: "/sign-up", element:<SignupForm /> },
    { path: "login", element: auth_token ? <Navigate to="/dashboard" /> : <LoginPage /> },
    { path: "counter", element: <Counter /> },
    { path: "forgot-password", element: auth_token ? <Navigate to="/dashboard" /> : <ForgotPasswordPage /> },
    { path: "reset-password", element: auth_token ? <Navigate to="/dashboard" /> : <ResetPasswordPage /> },
    { path: "*", element: <Navigate to="/login" /> }
  ]);
  return routing;
}

export default App;
