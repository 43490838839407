import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constant from "../../constant";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setHeaderTitle } from '../../store/slice/dashboardHeaderSlice';


function DishDetail() {
  
  const dispatch = useDispatch();
  dispatch(setHeaderTitle("Dish Detail"));

  const [data, setData] = useState([]);
  const [foodClass, setFoodClass] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const deleteDish = async (dish_id) => {
    try {
      let token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(constant.VENDOR_API_URL + "/deleteDish", { dish_id: dish_id }, { headers });
      setRefresh(!refresh);
      navigate(-1);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  const handleDeleteClick = (dishId) => {
    const confirmed = window.confirm("Are you sure you want to delete this dish?");
    if (confirmed) {
      deleteDish(dishId);
    }
  };
  useEffect(() => {
    fetchData();
  }, [refresh]);
  const fetchData = async () => {
    try {
      let token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.get(constant.VENDOR_API_URL + "/getDisheDetails", { params: { id: id }, headers });

      setData(response.data.data);
      setFoodClass(JSON.parse(response.data.data.food_class));

      setPageLoader(false);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <div class="dash-main-R detail-page">
      {pageLoader ? null : (
        <div class="dash-side-R-head d-flex justify-content-between align-items-center">
          <div class="dash-side-R-head-left">
            <h2>Dish Detail</h2>
          </div>
          <div class="dash-side-R-head-right d-flex align-items-center">
            <div class="dropdown profile-drop">
              <button
                class=" dropdown-toggle d-flex align-items-center"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <figure>
                  {" "}
                  <img src="images/profile-img.png" />
                </figure>
              </button>
            </div>
          </div>
        </div>
      )}



      <div class="dash-side-R-body">
        <div class="row order-detail-list-box mt-4">
          <div class="col-md-4">
            <figure class="dish-img">
              <img src={constant.BACKEND_URL + data.dish_image} />
            </figure>
            <div class="price-info">
              <h4>{data.name}</h4>
              <ul>
                {data?.food_class?.map((item, index) => {
                  return (
                    <>
                      <li>
                        <span>
                          <b>Food Class: </b> {item?.class}
                        </span>
                        <span>
                          <b>Price:</b> {`$${item?.price}`}
                        </span>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
            <ul class="order-detail-list">
              {data?.food_type?.map((item, index) => {
                return (
                  <li>
                    <b>Food Type:</b>
                    {item.name}
                  </li>
                );
              })}
              <li>
                <b>Spice Level:</b>
                <span>{data.spice_level}</span>
              </li>
              <li>
                <b>Cuisine:</b>
                <span>
                  {data?.cuisine?.map((item, index) => {
                    if(data?.cuisine.length-1 == index){
                      return item.name;
                    }else{
                      return item.name + ", ";
                    }
                    
                  })}
                </span>
              </li>

              <li>
                <b>Ingredients (For Ingredients Food Class):</b>
                <span>
                  {data?.class_ingredients?.map((item, index) => {

                    if(data?.class_ingredients.length-1 == index){
                      return item;
                    }else{
                      return item + ", ";
                    }
               
                  })}
                </span>
              </li>
              <li>
                <b>Ingredients:</b>
                <span>
                  {data?.ingredients?.map((item, index) => {
                    
                    if(data?.ingredients.length-1 == index){
                      return item.name;
                    }else{
                      return item.name + ", ";
                    }

                  })}
                </span>
              </li>
              <li>
                <b>Dietary Tags:</b>
                <span>
                  {data?.dietary_tag?.map((item, index) => {
                    
                    if(data?.dietary_tag.length-1 == index){
                      return item;
                    }else{
                      return item+ ", ";
                    }

                  })}
                </span>
              </li>
              <li>
                <b>Availability:</b>
                <span>{(data?.ordering_window?.window_type)?.replace(/_/g, " ")}</span>
              </li>
            </ul>
            <div class="btn-group-box mt-4">
              <Link to={`../edit-dish/${id}`} class="btn btn-primary">
                Edit
              </Link>
              <Link class="btn btn-danger ms-2" onClick={() => handleDeleteClick(data._id)}>
                Delete
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DishDetail;
