import React, { useEffect, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import { toast, ToastContainer } from 'react-toastify';
import StepBar from './StepBar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';


 function SignupForm() {
   const currentStep = sessionStorage.getItem("step");
   const [step, setStep] = useState(currentStep ? currentStep : 1); 
   return (
      <>
      <Header />
      <div className="wraper-inner  cpt">
            <section className="signup-box section-padding">
               <div className="container">               
                     <ToastContainer />
                     <div className="common-bg-form">
                        <div className="top-from-headning text-center mb-5">
                           <h3>Sign up with Eats</h3>
                           <p>Create an account with us</p>
                        </div>
                        <StepBar step={step} />
                     {step == 1 ? <Step1 setStep={(nextStep)=>{setStep(nextStep)}} /> 
                     :step == 2 ? <Step2 setStep={(nextStep)=>{setStep(nextStep)}} />
                     :step == 3 ? <Step3 setStep={(nextStep)=>{setStep(nextStep)}} />
                     :step == 4 ? <Step4 setStep={(nextStep)=>{setStep(nextStep)}} />
                     : <></>}   
                     </div>                            
               </div>
            </section>
         </div>
      <Footer />
      </>
   );
  
}

export default SignupForm;
