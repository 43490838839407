import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../store/slice/dashboardHeaderSlice";
 
function MyEarnings() {
    const dispatch = useDispatch();
    dispatch(setHeaderTitle("My Earnings"));
    return (
        <div className="wraper-inner  cpt">
            <section className="help-box-main section-padding">
                <div className="container">
                    <div className="section-head text-center mb-5">
                        <h2>Help</h2>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default MyEarnings