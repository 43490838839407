
import authReducer from './slice/authSlice';
import dashboardHeaderReducer from './slice/dashboardHeaderSlice';

const rootReducer = {
    auth: authReducer,
    dashboardHeader:dashboardHeaderReducer
    
};


export default rootReducer;