import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as constant from "../../constant";
import Select from "react-select";
import { Calendar } from "react-multi-date-picker";
import { ToastContainer, toast } from "react-toastify";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../../store/slice/dashboardHeaderSlice";

function AddSet() {
  const dispatch = useDispatch();
  dispatch(setHeaderTitle("Add New Set"));

  const [setImage, setDishImageURL] = useState("/images/dish-img.png");
  const [communitiesList, setCommunitiesList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = localStorage.getItem("token");
        const headers = { Authorization: "Bearer " + token };
        const response = await axios.get(constant.VENDOR_API_URL + "/getAllCuisine", { headers });
        const communities = await axios.get(constant.VENDOR_API_URL + "/getAllCommunities", { headers });
        setCommunitiesList(communities.data.data);
        let couisin_data = response.data.data.map((item, index) => {
          item.label = item.name;
          item.value = item._id;
          return item;
        });
        setOptions(couisin_data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchData();
  }, []);

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, " Set Name must be at least 3 characters ")
      .max(55, " Set Name must be less than 55 characters ")
      .required("Set Name is  required")
      .matches(/^[A-Za-z ]*$/, "Set name is not valid"),
    set_from_time: Yup.boolean(),
    set_to_time: Yup.boolean(),
    set_week_day: Yup.boolean(),
    ingredients_price: Yup.boolean(),
    ready_to_cook_price: Yup.boolean(),
    ready_to_heat_price: Yup.boolean(),
    ready_to_eat_price: Yup.boolean(),

    groups: Yup.array().of(
      Yup.object().shape({
        group_name: Yup.string()
          .required("Group Name required")
          .min(3, "Group Name must be at least 3 characters ")
          .max(55, "Group Name must be less than 55 characters ")
          .matches(/^[A-Za-z ]*$/, "Group name is not valid"),
        item_number: Yup.string()
          // .strict(true).trim("Extra spaces should be truncated")
          .required("Item Number is required ")
          .matches(/^\d+$/, "Enter valid Item Number")

          .test("maxTwoDigits", "Item Number should not be more than 500", (number) => Number(number) <= 500),
        communities: Yup.array()
          .of(Yup.string().required("Communities is required"))
          .required("Must have Communities")
          .min(1, "Minimum of 1 Communities"),
      })
    ),
    Ingredients: Yup.string().when("ingredients_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ingredients  price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Cook": Yup.string().when("ready_to_cook_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Cook  price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Heat": Yup.string().when("ready_to_heat_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Heat  price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    "Ready to Eat": Yup.string().when("ready_to_eat_price", {
      is: true, // alternatively: (val) => val == true
      then: (schema) =>
        schema
          .required("Ready to Eat  price is required")
          .matches(/^\d+(?:\.\d{1,3})?$/, "Enter valid Price")
          .test("maxTwoDigits", "price should not be more than $500", (number) => Number(number) <= 500),
      otherwise: (schema) => schema.min(0),
    }),
    food_class: Yup.array().min(1, "Class of food is required"),
    cuisine: Yup.array().min(1, "Please add cuisine"),
    set_image: Yup.mixed().required("Set Image is required"),
    description: Yup.string()
      .required("Description is required")
      .min(5, "Description must be at least 5 characters ")
      .max(500, "Description must be less than 500 characters "),
    ordering_window: Yup.object().shape({
      window_type: Yup.string(),
      days: Yup.array().when("window_type", {
        is: "weekly",
        then: (schema) => schema.min(1, "Please select at least one day."),
      }),
      dates: Yup.array().when("window_type", {
        is: "monthly",
        then: (schema) => schema.min(1, "Please select at least one date."),
      }),
      from_time: Yup.string().when("window_type", {
        is: (val) => val !== "any time",
        then: (schema) =>
          schema.test("custom-validation", "Invalid: To time cannot be before From time", function (value) {
            const closeTime = this.parent.to_time;
            if (closeTime && value && closeTime <= value) {
              return false;
            }
            return true;
          }),
      }),
      to_time: Yup.string().when("window_type", {
        is: (val) => val !== "any time",
        then: (schema) =>
          schema.test("custom-validation", "Invalid: From time cannot be after To time", function (value) {
            const openTime = this.parent.from_time;
            if (openTime && value && openTime >= value) {
              return false;
            }
            return true;
          }),
      }),
    }),
    Processing_time: Yup.object().shape({
      time_type: Yup.string(),
      // number: Yup.string().when("time_type", {
      //   is: (val) => ["advance-order", "days", "hours"].includes(val),
      //   then: (schema) => schema.test("maxTwoDigits", "number should not be more than 0", (number) => Number(number) > 0),
      // }),
      weekDays: Yup.array().when("time_type", {
        is: "weekly-on",
        then: (schema) => schema.min(1, "Please select at least one day."),
      }),
      week1: Yup.array().when("time_type", {
        is: "bi-weekly",
        then: (schema) => schema.min(1, "Please select at least one day."),
      }),
      week2: Yup.array().when("time_type", {
        is: "bi-weekly",
        then: (schema) => schema.min(1, "Please select at least one day."),
      }),
      onDays: Yup.array().of(
        Yup.object().shape({
          available: Yup.boolean(),
          open_time: Yup.string().test("custom-validation", "Invalid: To time cannot be before From time", function (value) {
            const closeTime = this.parent.close_time;
            if (closeTime && value && closeTime <= value) {
              return false;
            }
            return true;
          }),
          close_time: Yup.string().test("custom-validation", "Invalid: From time cannot be after To time", function (value) {
            const openTime = this.parent.open_time;
            if (openTime && value && openTime >= value) {
              return false;
            }
            return true;
          }),
        })
      ),
      time_dates: Yup.array().when("time_type", {
        is: (val) => ["monthly-on", "on-dates"].includes(val),
        then: (schema) => schema.min(1, "Please select at least one date."),
      }),
      from_time1: Yup.string().when("time_type", {
        is: (val) => ["daily", "days", "on-dates"].includes(val),
        then: (schema) =>
          schema.test("custom-validation", "Invalid: To time cannot be before From time", function (value) {
            const closeTime = this.parent.to_time1;
            if (closeTime && value && closeTime <= value) {
              return false;
            }
            return true;
          }),
      }),
      to_time1: Yup.string().when("time_type", {
        is: (val) => ["daily", "days", "on-dates"].includes(val),
        then: (schema) =>
          schema.test("custom-validation", "Invalid: From time cannot be after To time", function (value) {
            const openTime = this.parent.from_time1;
            if (openTime && value && openTime >= value) {
              return false;
            }
            return true;
          }),
      }),
    }),
  });

  return (
    <div class="dash-side-R-body">
      <div class="row">
        <div class="col-md-8 m-auto">
          <div class="form-box signup-form col-md-8 m-auto">
            <div class="row">
              <Formik
                initialValues={{
                  name: "",
                  cuisine: [],
                  set_image: "",
                  food_class: [],
                  description: "",
                  groups: [
                    {
                      group_name: "",
                      communities: [""],
                      item_number: "",
                    },
                  ],
                  food_class: [],
                  recurring: 0,
                  status: 1,
                  Ingredients: "",
                  "Ready to Cook": "",
                  "Ready to Heat": "",
                  "Ready to Eat": "",
                  ingredients_price: false,
                  ready_to_cook_price: false,
                  ready_to_heat_price: false,
                  ready_to_eat_price: false,
                  ordering_window: {
                    window_type: "any time",
                    days: [],
                    dates: [],
                    from_time: "10:00",
                    to_time: "21:00",
                  },
                  Processing_time: {
                    time_type: "immediate",
                    number: 1,
                    weekDays: [],
                    week1: [],
                    week2: [],
                    onDays: [
                      { day: "Monday", available: false, open_time: "10:00", close_time: "21:00" },
                      { day: "Tuesday", available: false, open_time: "10:00", close_time: "21:00" },
                      { day: "Wednesday", available: false, open_time: "10:00", close_time: "21:00" },
                      { day: "Thursday", available: false, open_time: "10:00", close_time: "21:00" },
                      { day: "Friday", available: false, open_time: "10:00", close_time: "21:00" },
                      { day: "Saturday", available: false, open_time: "10:00", close_time: "21:00" },
                      { day: "Sunday", available: false, open_time: "10:00", close_time: "21:00" },
                    ],
                    time_dates: [],
                    from_time1: "10:00",
                    to_time1: "21:00",
                  },
                }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  let formData = new FormData();
                  formData.append("name", values.name);
                  formData.append("description", values.description);
                  formData.append("cuisine", JSON.stringify(values.cuisine));
                  formData.append("recurring", values.recurring);
                  formData.append("dish_image", values.set_image);
                  formData.append("ordering_window.window_type", values.window_type);
                  formData.append("ordering_window.from_time", values.from_time);
                  formData.append("ordering_window.to_time", values.to_time);
                  formData.append("ordering_window.days", JSON.stringify(values.week_day));
                  formData.append("set_contents", JSON.stringify(values.groups));
                  formData.append("food_class", JSON.stringify(values.food_class));
                  formData.append("ordering_window", JSON.stringify(values.ordering_window));
                  formData.append("Processing_time", JSON.stringify(values.Processing_time));

                  try {
                    let token = localStorage.getItem("token");
                    const headers = { Authorization: "Bearer " + token };
                    const response = await axios.post(constant.VENDOR_API_URL + "/addSet", formData, { headers });
                    toast.success("Set added successfully!", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                    navigate("/dashboard/menu");
                  } catch (error) {
                    toast.error(error.response.data.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }
                }}>
                {({ errors, touched, handleChange, handleBlur, values, isSubmitting, setFieldValue, setFieldError }) => (
                  <Form>
                    <ToastContainer />
                    <div class="col-md-12">
                      <div class="upload-photo">
                        <div class="avatar-upload">
                          <div class="avatar-edit">
                            <input
                              id="imageUpload"
                              type="file"
                              name="set_image"
                              accept=".png, .jpg, .jpeg"
                              onChange={(event) => {
                                if (
                                  event.target.files[0].type == "image/png" ||
                                  event.target.files[0].type == "image/jpg" ||
                                  event.target.files[0].type == "image/jpeg"
                                ) {
                                  if (event.target.files[0].size <= 1 * 1024 * 1024) {
                                    setFieldValue("set_image", event.currentTarget.files[0]);
                                    let image_url = URL.createObjectURL(event.currentTarget.files[0]);
                                    setDishImageURL(image_url);
                                  } else {
                                    toast.error("Size must be less than 1 MB", {
                                      position: toast.POSITION.TOP_RIGHT,
                                    });
                                    setFieldError("set_image", "Size must be less than 1 MB");
                                  }
                                } else {
                                  toast.error("Invalid File Format", {
                                    position: toast.POSITION.TOP_RIGHT,
                                  });
                                  setFieldError("set_image", "Invalid File Format");
                                }
                              }}
                            />
                            <label for="imageUpload" class="img-upload-icon">
                              <i class="ri-edit-box-line"></i>
                            </label>
                          </div>
                          <div class="avatar-preview">
                            <div id="imagePreview" class="avatar-preview-img">
                              <img src={setImage && setImage} />
                            </div>
                          </div>
                        </div>
                        <span class="validation-error" style={{ display: "flex" }}>
                          {errors.set_image && touched.set_image && errors.set_image}
                        </span>
                      </div>
                    </div>

                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Set Name</label>
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name.replace(/\s\s+/g, " ")}
                        placeholder="Enter Set name"
                        class="form-control"
                      />
                      <span class="validation-error">{errors.name && touched.name && errors.name}</span>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-4 d-flex justify-content-between">
                        <label class="form-label">Available As Recurring?</label>
                        <label class="switch-custom md-swich">
                          <input
                            type="checkbox"
                            name="recurring"
                            defaultChecked={values.recurring}
                            onBlur={handleBlur}
                            value={values.recurring}
                            onChange={(e) => {
                              let { value, checked } = e.target;
                              if (checked) {
                                setFieldValue("recurring", true);
                              } else {
                                setFieldValue("recurring", false);
                              }
                            }}
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group mb-4 d-flex justify-content-between">
                        <label class="form-label">Enabled/Disabled</label>
                        <label class="switch-custom md-swich">
                          <input
                            type="checkbox"
                            name="status"
                            defaultChecked={values.status}
                            onBlur={handleBlur}
                            value={values.status}
                            onChange={(e) => {
                              let { value, checked } = e.target;
                              if (checked) {
                                setFieldValue("status", true);
                              } else {
                                setFieldValue("status", false);
                              }
                            }}
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Cuisine</label>
                      <Select
                        defaultValue={selectedOption}
                        isMulti
                        onChange={(value) => {
                          setFieldValue(
                            "cuisine",
                            value.map((item) => {
                              return item.value;
                            })
                          );
                        }}
                        options={options}
                      />

                      <span class="validation-error">{errors.cuisine && touched.cuisine && errors.cuisine}</span>
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Class Of Food</label>
                      <ul class="box-check-box-list d-flex">
                        <li class="me-4">
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ingredients_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ingredients",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ingredients_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ingredients")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-1.png" />
                              </figure>
                              <h4>Ingredients</h4>
                            </div>
                          </label>
                        </li>
                        <li class="me-4">
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ready_to_cook_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ready to Cook",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ready_to_cook_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ready to Cook")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-2.png" />
                              </figure>
                              <h4>Ready to Cook</h4>
                            </div>
                          </label>
                        </li>
                        <li class="me-4">
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ready_to_heat_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ready to Heat",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ready_to_heat_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ready to Heat")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-3.png" />
                              </figure>
                              <h4>Ready to Heat</h4>
                            </div>
                          </label>
                        </li>
                        <li>
                          <label class="coustom-checkbox box-check-box">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let { value, checked } = e.target;
                                if (checked) {
                                  setFieldValue("ready_to_eat_price", true);
                                  setFieldValue("food_class", [
                                    ...values.food_class,
                                    {
                                      class: "Ready to Eat",
                                      price: "",
                                    },
                                  ]);
                                } else {
                                  setFieldValue("ready_to_eat_price", false);
                                  setFieldValue(
                                    "food_class",
                                    values.food_class.filter((e) => e.class !== "Ready to Eat")
                                  );
                                }
                              }}
                            />
                            <span class="checkmark"></span>
                            <div class="content-check">
                              <figure>
                                <img src="/images/food-icon-4.png" />
                              </figure>
                              <h4>Ready to Eat</h4>
                            </div>
                          </label>
                        </li>
                      </ul>

                      <span class="validation-error">{errors.food_class && touched.food_class && errors.food_class}</span>
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Price</label>
                      <p class="mb-3">
                        You earn $x from each dish sold! Eats collects $y (z%) to cover marketing, operations, customer support and software
                        development costs."
                      </p>
                      <div class="row row-cols-2">
                        {values.food_class.map((item, index) => {
                          return (
                            <div class="cols" key={index}>
                              <input
                                type="text"
                                name={item.class}
                                placeholder={`Enter Price for ${item.class}`}
                                class="form-control price_box"
                                value={item.price.replace(/\s\s+/g, " ")}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(`food_class.${index}.price`, e.target.value);
                                  setFieldValue(`${item.class}`, e.target.value);
                                }}
                              />
                             <span class="validation-error">{errors[item.class]}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <hr class="divider-accordion" />
                    </div>
                    <div class="form-group mb-4 col-md-12">
                      <label class="form-label">Description</label>
                      <textarea
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description.replace(/ +/g, " ")}
                        placeholder="Enter Description"
                        class="form-control note"
                      />
                      <span class="validation-error">{errors.description && touched.description && errors.description}</span>
                    </div>
                    <h4 class="inner-title mb-3">Set Contents</h4>
                    <FieldArray
                      name="groups"
                      render={(groupHelpers) => (
                        <div>
                          {values.groups.map((groups, key) => (
                            <div class="form-group mb-4 col-md-12 bg-grey-box">
                              <div class="row">
                                <div class="add-more d-flex align-items-center mt-3">
                                  <label>Group {key + 1}</label>
                                </div>

                                <div class="form-group mb-4 col-md-12">
                                  <label class="form-label">Group Name</label>
                                 
                                  <Field
                                    type="text"
                                    name={`groups.${key}.group_name`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.groups[key].group_name.replace(/ +/g, " ")}
                                    placeholder="Enter Group Name"
                                    class="form-control"
                                  />
                                  <span class="validation-error">
                                    <ErrorMessage name={`groups.${key}.group_name`} />
                                  </span>

                                </div>

                                <label class="form-label">Set Communities Name</label>

                                <FieldArray
                                  name={`groups.${key}.communities`}
                                  render={(arrayHelpers) => (
                                    <div>

                                      
                                      {values.groups[key].communities.map((communities, index) => (
                                        <div key={index}>
                                          <div class="input-add-box d-flex justify-content-between align-items-center" key={index}>
                                            <Field
                                              as="select"
                                              name={`groups.${key}.communities.${index}`}
                                              placeholder="Enter Communities"
                                              class="form-control"
                                              onChange={(e) => {
                                                const com_array = values.groups[key].communities.filter((str) => str !== "");
                                                let is_exits = com_array.includes(e.target.value);

                                          

                                                if (is_exits) {
                                                  return false;
                                                }

                                                setFieldValue(`groups.${key}.communities.${index}`, e.target.value);
                                              }}>
                                              <option value="">Select Communities</option>
                                              {communitiesList.map((com) => {
                                                return <option value={com._id}>{com.name}</option>;
                                              })}
                                            </Field>
                                            {index !== 0 && (
                                              <a class="action-box delete-action ms-3" onClick={() => arrayHelpers.remove(index)}>
                                                <i class="ri-delete-bin-line"></i>
                                              </a>
                                            )}
                                          </div>
                                          <div>
                                            <span class="validation-error">
                                              <ErrorMessage name={`groups.${key}.communities.${index}`} />
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                      <div class="add-more d-flex align-items-center mt-3">
                                        <a class="action-box  me-2" onClick={() => arrayHelpers.push("")}>
                                          <i class="ri-add-fill"></i>
                                        </a>
                                        Add more
                                      </div>
                                    </div>
                                  )}
                                />
                                <div class="form-group mb-4 col-md-12">
                                  <label class="form-label">Number Of Items Needed To Chosen</label>

                                  <Field
                                    name={`groups.${key}.item_number`}
                                    placeholder="Enter Number Of Items Needed To Chosen"
                                    class="form-control"
                                  />

                                  <span class="validation-error">
                                    <ErrorMessage name={`groups.${key}.item_number`} />
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div class="add-more d-flex align-items-center mt-3">
                            <a
                              class="action-box  me-2"
                              onClick={() =>
                                groupHelpers.push({
                                  group_name: "",
                                  communities: [""],
                                  item_number: "",
                                })
                              }>
                              <i class="ri-add-fill"></i>
                            </a>
                            Add more
                          </div>
                        </div>
                      )}
                    />
                    <div class="col-md-12">
                      <hr class="divider-accordion" />
                    </div>
                    <div class="form-group mb-4 col-md-12 mb-4">
                      <div class="label-box d-flex justify-content-between">
                        <label class="form-label">Ordering Window</label>
                        <span class="tool-tip-box con-tooltip top">
                          <i class="ri-information-line"></i>
                          <div class="tooltip ">
                            <p>Top</p>
                          </div>
                        </span>
                      </div>
                      <div class="radio-wrap d-flex align-items-center">
                        <label class="radio-coustom-design me-4 mb-0">
                          <input
                            type="radio"
                            name="ordering_window.window_type"
                            value="any time"
                            defaultChecked
                            onChange={(e) => {
                              setFieldValue("ordering_window.window_type", e.target.value);
                            }}
                          />
                          <span class="checkmark"></span>
                          <p>Any Time</p>
                        </label>
                        <label class="radio-coustom-design me-4 mb-0">
                          <input
                            type="radio"
                            name="ordering_window.window_type"
                            value="weekly"
                            onChange={(e) => {
                              setFieldValue("ordering_window.window_type", e.target.value);
                            }}
                          />
                          <span class="checkmark"></span>
                          <p>Weekly</p>
                        </label>
                        <label class="radio-coustom-design mb-0 ">
                          <input
                            type="radio"
                            name="ordering_window.window_type"
                            value="monthly"
                            onChange={(e) => {
                              setFieldValue("ordering_window.window_type", e.target.value);
                            }}
                          />
                          <span class="checkmark"></span>

                          <p>Monthly</p>
                        </label>
                      </div>
                      {values.ordering_window?.window_type === "weekly" && (
                        <>
                          <div class="custom-check-label d-flex mb-3 mt-3">
                            <label class="coustom-checkbox me-4 sm-check-box">
                              <input
                                type="checkbox"
                                name="ordering_window.days"
                                value="sunday"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ordering_window.days", [...values.ordering_window.days, value]);
                                  } else {
                                    setFieldValue(
                                      "ordering_window.days",
                                      values.ordering_window.days.filter((e) => e !== value)
                                    );
                                  }
                                }}
                              />

                              <span class="checkmark"></span>
                              <p>Sunday</p>
                            </label>
                            <label class="coustom-checkbox me-4 sm-check-box">
                              <input
                                type="checkbox"
                                name="ordering_window.days"
                                value="monday"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ordering_window.days", [...values.ordering_window.days, value]);
                                  } else {
                                    setFieldValue(
                                      "ordering_window.days",
                                      values.ordering_window.days.filter((e) => e !== value)
                                    );
                                  }
                                }}
                              />

                              <span class="checkmark"></span>
                              <p>Monday</p>
                            </label>
                            <label class="coustom-checkbox me-4 sm-check-box">
                              <input
                                type="checkbox"
                                name="ordering_window.days"
                                value="tuesday"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ordering_window.days", [...values.ordering_window.days, value]);
                                  } else {
                                    setFieldValue(
                                      "ordering_window.days",
                                      values.ordering_window.days.filter((e) => e !== value)
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <p>Tuesday</p>
                            </label>
                            <label class="coustom-checkbox me-4 sm-check-box">
                              <input
                                type="checkbox"
                                name="ordering_window.days"
                                value="wednesday"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ordering_window.days", [...values.ordering_window.days, value]);
                                  } else {
                                    setFieldValue(
                                      "ordering_window.days",
                                      values.ordering_window.days.filter((e) => e !== value)
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <p>Wednesday</p>
                            </label>
                            <label class="coustom-checkbox sm-check-box me-4 ">
                              <input
                                type="checkbox"
                                name="ordering_window.days"
                                value="thursday"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ordering_window.days", [...values.ordering_window.days, value]);
                                  } else {
                                    setFieldValue(
                                      "ordering_window.days",
                                      values.ordering_window.days.filter((e) => e !== value)
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <p>Thursday</p>
                            </label>
                            <label class="coustom-checkbox sm-check-box me-4 ">
                              <input
                                type="checkbox"
                                name="ordering_window.days"
                                value="friday"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ordering_window.days", [...values.ordering_window.days, value]);
                                  } else {
                                    setFieldValue(
                                      "ordering_window.days",
                                      values.ordering_window.days.filter((e) => e !== value)
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <p>Friday</p>
                            </label>
                            <label class="coustom-checkbox sm-check-box">
                              <input
                                type="checkbox"
                                name="ordering_window.days"
                                value="saturday"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let { value, checked } = e.target;
                                  if (checked) {
                                    setFieldValue("ordering_window.days", [...values.ordering_window.days, value]);
                                  } else {
                                    setFieldValue(
                                      "ordering_window.days",
                                      values.ordering_window.days.filter((e) => e !== value)
                                    );
                                  }
                                }}
                              />
                              <span class="checkmark"></span>
                              <p>Saturday</p>
                            </label>
                          </div>
                          <span class="validation-error">{errors.ordering_window?.days}</span>
                        </>
                      )}
                      {values.ordering_window?.window_type === "monthly" && (
                        <>
                          <div class="col-md-12 mb-4">
                            <figure className="calender-box">
                              <Calendar
                                value={values.ordering_window.dates}
                                onChange={(e) => {
                                  const dates = e.map((date_obj, index) => new Date(date_obj));
                                  setFieldValue("ordering_window.dates", dates);
                                }}
                              />
                            </figure>
                          </div>
                          <span class="validation-error">{errors.ordering_window?.dates}</span>
                        </>
                      )}
                      {values.ordering_window?.window_type !== "any time" && (
                        <div class="row">
                          <div class="col-md-6 time-select">
                            <TimePicker
                              placeholder="Select From Time"
                              showSecond={false}
                              focusOnOpen={true}
                              onChange={(e) => {
                                const time =
                                      new Date(e)
                                        .getHours()
                                        .toString()
                                        .padStart(2, "0") +
                                      ":" +
                                      new Date(e)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");
                                setFieldValue("ordering_window.from_time", time);
                              }}
                            />
                            <span class="validation-error">{errors.ordering_window?.from_time}</span>
                          </div>

                          <div class="col-md-6 time-select">
                            <TimePicker
                              placeholder="Select To Time"
                              showSecond={false}
                              focusOnOpen={true}
                              onChange={(e) => {
                                const time =
                                      new Date(e)
                                        .getHours()
                                        .toString()
                                        .padStart(2, "0") +
                                      ":" +
                                      new Date(e)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");
                                setFieldValue("ordering_window.to_time", time);
                              }}
                            />
                            <span class="validation-error">{errors.ordering_window?.to_time}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="col-md-12">
                      <hr class="divider-accordion" />
                    </div>
                    <div class="col-md-12">
                      <div class="label-box d-flex justify-content-between">
                        <label class="form-label">Order Processing Time</label>
                        <span class="tool-tip-box con-tooltip top">
                          <i class="ri-information-line"></i>
                          <div class="tooltip ">
                            <p>Top</p>
                          </div>
                        </span>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <a class="btn-show">
                            Default: <span> Immediate 24/7</span>
                          </a>
                          <div class="border-box mb-3">
                            <div class="radio-wrap">
                              <label class="radio-coustom-design me-4">
                                <input
                                  type="radio"
                                  defaultChecked
                                  name="Processing_time.time_type"
                                  value="immediate"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>Immediate</p>
                              </label>
                              <label class="radio-coustom-design me-4 with-incre">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="hours"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>Hours</p>
                                {values.Processing_time.time_type === "hours" && (
                                  <div className="hours-incre">
                                    <button
                                      type="button"
                                      className="btn-minus"
                                      onClick={() => {
                                        if (values.Processing_time.number > 1) {
                                          setFieldValue("Processing_time.number", values.Processing_time.number - 1);
                                        }
                                      }}>
                                      <i class="ri-subtract-line"></i>
                                    </button>

                                    <div className="incre-value">{values.Processing_time.number}</div>
                                    <button
                                      type="button"
                                      className="btn-plus"
                                      onClick={() => {
                                        if (values.Processing_time.number < 50) {
                                          setFieldValue("Processing_time.number", values.Processing_time.number + 1);
                                        }
                                      }}>
                                      <i class="ri-add-fill"></i>
                                    </button>
                                    <span className="days-text">hrs</span>
                                  </div>
                                )}
                              </label>
                              <label class="radio-coustom-design ">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="daily"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />

                                <span class="checkmark"></span>

                                <p>Daily</p>
                              </label>
                              <label class="radio-coustom-design with-incre">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="days"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>Days</p>
                                {values.Processing_time.time_type === "days" && (
                                  <div className="hours-incre">
                                    <button
                                      className="btn-minus"
                                      type="button"
                                      onClick={() => {
                                        if (values.Processing_time.number > 1) {
                                          setFieldValue("Processing_time.number", values.Processing_time.number - 1);
                                        }
                                      }}>
                                      <i class="ri-subtract-line"></i>
                                    </button>
                                    <div className="incre-value">{values.Processing_time.number}</div>
                                    <button
                                      className="btn-plus"
                                      type="button"
                                      onClick={() => {
                                        if (values.Processing_time.number < 50) {
                                          setFieldValue("Processing_time.number", values.Processing_time.number + 1);
                                        }
                                      }}>
                                      <i class="ri-add-fill"></i>
                                    </button>
                                    <span className="days-text">days</span>
                                  </div>
                                )}
                              </label>
                              <label class="radio-coustom-design ">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="on-days"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>On Days</p>
                              </label>
                              <label class="radio-coustom-design ">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="on-dates"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>On Dates</p>
                              </label>
                            </div>
                          </div>
                          <label class="form-label">Batch Processed</label>
                          <div class="border-box">
                            <div class="radio-wrap">
                              <label class="radio-coustom-design me-4">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="weekly-on"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>Weekly On</p>
                              </label>
                              <label class="radio-coustom-design me-4">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="bi-weekly"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>Bi-Weekly On</p>
                              </label>
                              <label class="radio-coustom-design ">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="monthly-on"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>Monthly On</p>
                              </label>
                              <label class="radio-coustom-design with-incre">
                                <input
                                  type="radio"
                                  name="Processing_time.time_type"
                                  value="advance-order"
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.time_type", value);
                                    }
                                  }}
                                />
                                <span class="checkmark"></span>
                                <p>Advance Order</p>
                                {values.Processing_time.time_type === "advance-order" && (
                                  <div className="hours-incre">
                                    <button
                                      type="button"
                                      className="btn-minus"
                                      onClick={() => {
                                        if (values.Processing_time.number > 1) {
                                          setFieldValue("Processing_time.number", values.Processing_time.number - 1);
                                        }
                                      }}>
                                      <i class="ri-subtract-line"></i>
                                    </button>
                                    <div className="incre-value">{values.Processing_time.number}</div>
                                    <button
                                      type="button"
                                      className="btn-plus"
                                      onClick={() => {
                                        if (values.Processing_time.number < 50) {
                                          setFieldValue("Processing_time.number", values.Processing_time.number + 1);
                                        }
                                      }}>
                                      <i class="ri-add-fill"></i>
                                    </button>
                                    <span className="days-text">days</span>
                                  </div>
                                )}
                              </label>
                            </div>
                          </div>

                          {/* <span class="validation-error">{errors.Processing_time?.number}</span> */}
                        </div>
                        <div class="col-md-7">
                          <div class="text-show-pannel">
                            <h4>Settings Guide</h4>
                            <div class="text-show-white">
                              <h5>Immediate Order (upon order)</h5>
                              <p>
                                Food is an essential part of our daily lives. It provides us with the energy and nutrients we need to stay
                                healthy and active. There are many different types of food available, each with its own unique taste and
                                nutritional benefits. Some foods are high in protein, such as meat, fish, and{" "}
                              </p>
                              <p>
                                Food is an essential part of our daily lives. It provides us with the energy and nutrients we need to stay
                                healthy and active. There are many different types of food available, each with its own unique taste and
                                nutritional benefits. Some foods are high in protein, such as meat, fish, and beans, while others are rich{" "}
                              </p>

                              <div class=" text-end">
                                <a class="read-more">
                                  <img src="/images/read-more-arrow.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {values.Processing_time.time_type === "bi-weekly" && (
                        <div className="row weekely-row mt-4">
                          <label class="form-label">Batch Processed</label>
                          <div className="col-md-6">
                            <small className="sub-green-title">Week 1</small>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week1"
                                  value="monday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week1", [...values.Processing_time.week1, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week1",
                                        values.Processing_time.week1.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />

                                <span class="slider round"></span>
                              </label>
                              <h3>Monday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week1"
                                  value="tuesday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week1", [...values.Processing_time.week1, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week1",
                                        values.Processing_time.week1.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Tuesday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week1"
                                  value="wednesday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week1", [...values.Processing_time.week1, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week1",
                                        values.Processing_time.week1.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Wednesday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week1"
                                  value="thursday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week1", [...values.Processing_time.week1, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week1",
                                        values.Processing_time.week1.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Thursday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week1"
                                  value="friday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week1", [...values.Processing_time.week1, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week1",
                                        values.Processing_time.week1.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Friday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week1"
                                  value="saturday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week1", [...values.Processing_time.week1, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week1",
                                        values.Processing_time.week1.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Saturday</h3>
                            </div>

                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week1"
                                  value="sunday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week1", [...values.Processing_time.week1, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week1",
                                        values.Processing_time.week1.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Sunday</h3>
                            </div>
                            <span class="validation-error">{errors.Processing_time?.week1}</span>
                          </div>

                          <div className="col-md-6">
                            <small className="sub-green-title">Week 2</small>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week2"
                                  value="monday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week2", [...values.Processing_time.week2, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week2",
                                        values.Processing_time.week2.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />

                                <span class="slider round"></span>
                              </label>
                              <h3>Monday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week2"
                                  value="tuesday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week2", [...values.Processing_time.week2, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week2",
                                        values.Processing_time.week2.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Tuesday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week2"
                                  value="wednesday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week2", [...values.Processing_time.week2, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week2",
                                        values.Processing_time.week2.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Wednesday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week2"
                                  value="thursday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week2", [...values.Processing_time.week2, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week2",
                                        values.Processing_time.week2.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Thursday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week2"
                                  value="friday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week2", [...values.Processing_time.week2, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week2",
                                        values.Processing_time.week2.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Friday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week2"
                                  value="saturday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week2", [...values.Processing_time.week2, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week2",
                                        values.Processing_time.week2.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Saturday</h3>
                            </div>
                            <div className="weekely-tabs">
                              <label class="switch-custom sm-switch">
                                <input
                                  type="checkbox"
                                  name="Processing_time.week2"
                                  value="sunday"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    let { value, checked } = e.target;
                                    if (checked) {
                                      setFieldValue("Processing_time.week2", [...values.Processing_time.week2, value]);
                                    } else {
                                      setFieldValue(
                                        "Processing_time.week2",
                                        values.Processing_time.week2.filter((e) => e !== value)
                                      );
                                    }
                                  }}
                                />
                                <span class="slider round"></span>
                              </label>
                              <h3>Sunday</h3>
                            </div>
                            <span class="validation-error">{errors.Processing_time?.week2}</span>
                          </div>
                        </div>
                      )}
                      {values.Processing_time.time_type === "weekly-on" && (
                        <>
                          <div className="row weekely-row mt-4">
                            <label class="form-label">Weekly On</label>
                            <div className="col-md-6">
                              <div className="weekely-tabs">
                                <label class="switch-custom sm-switch">
                                  <input
                                    type="checkbox"
                                    name="Processing_time.weekDays"
                                    value="monday"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      let { value, checked } = e.target;
                                      if (checked) {
                                        setFieldValue("Processing_time.weekDays", [...values.Processing_time.weekDays, value]);
                                      } else {
                                        setFieldValue(
                                          "Processing_time.weekDays",
                                          values.Processing_time.weekDays.filter((e) => e !== value)
                                        );
                                      }
                                    }}
                                  />

                                  <span class="slider round"></span>
                                </label>
                                <h3>Monday</h3>
                              </div>
                              <div className="weekely-tabs">
                                <label class="switch-custom sm-switch">
                                  <input
                                    type="checkbox"
                                    name="Processing_time.weekDays"
                                    value="tuesday"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      let { value, checked } = e.target;
                                      if (checked) {
                                        setFieldValue("Processing_time.weekDays", [...values.Processing_time.weekDays, value]);
                                      } else {
                                        setFieldValue(
                                          "Processing_time.weekDays",
                                          values.Processing_time.weekDays.filter((e) => e !== value)
                                        );
                                      }
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <h3>Tuesday</h3>
                              </div>
                              <div className="weekely-tabs">
                                <label class="switch-custom sm-switch">
                                  <input
                                    type="checkbox"
                                    name="Processing_time.weekDays"
                                    value="wednesday"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      let { value, checked } = e.target;
                                      if (checked) {
                                        setFieldValue("Processing_time.weekDays", [...values.Processing_time.weekDays, value]);
                                      } else {
                                        setFieldValue(
                                          "Processing_time.weekDays",
                                          values.Processing_time.weekDays.filter((e) => e !== value)
                                        );
                                      }
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <h3>Wednesday</h3>
                              </div>
                              <div className="weekely-tabs">
                                <label class="switch-custom sm-switch">
                                  <input
                                    type="checkbox"
                                    name="Processing_time.weekDays"
                                    value="thursday"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      let { value, checked } = e.target;
                                      if (checked) {
                                        setFieldValue("Processing_time.weekDays", [...values.Processing_time.weekDays, value]);
                                      } else {
                                        setFieldValue(
                                          "Processing_time.weekDays",
                                          values.Processing_time.weekDays.filter((e) => e !== value)
                                        );
                                      }
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <h3>Thursday</h3>
                              </div>
                              <div className="weekely-tabs">
                                <label class="switch-custom sm-switch">
                                  <input
                                    type="checkbox"
                                    name="Processing_time.weekDays"
                                    value="friday"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      let { value, checked } = e.target;
                                      if (checked) {
                                        setFieldValue("Processing_time.weekDays", [...values.Processing_time.weekDays, value]);
                                      } else {
                                        setFieldValue(
                                          "Processing_time.weekDays",
                                          values.Processing_time.weekDays.filter((e) => e !== value)
                                        );
                                      }
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <h3>Friday</h3>
                              </div>
                              <div className="weekely-tabs">
                                <label class="switch-custom sm-switch">
                                  <input
                                    type="checkbox"
                                    name="Processing_time.weekDays"
                                    value="saturday"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      let { value, checked } = e.target;
                                      if (checked) {
                                        setFieldValue("Processing_time.weekDays", [...values.Processing_time.weekDays, value]);
                                      } else {
                                        setFieldValue(
                                          "Processing_time.weekDays",
                                          values.Processing_time.weekDays.filter((e) => e !== value)
                                        );
                                      }
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <h3>Saturday</h3>
                              </div>
                              <div className="weekely-tabs">
                                <label class="switch-custom sm-switch">
                                  <input
                                    type="checkbox"
                                    name="Processing_time.weekDays"
                                    value="sunday"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      let { value, checked } = e.target;
                                      if (checked) {
                                        setFieldValue("Processing_time.weekDays", [...values.Processing_time.weekDays, value]);
                                      } else {
                                        setFieldValue(
                                          "Processing_time.weekDays",
                                          values.Processing_time.weekDays.filter((e) => e !== value)
                                        );
                                      }
                                    }}
                                  />
                                  <span class="slider round"></span>
                                </label>
                                <h3>Sunday</h3>
                              </div>
                            </div>
                          </div>
                          <span class="validation-error">{errors.Processing_time?.weekDays}</span>
                        </>
                      )}
                      {values.Processing_time.time_type === "monthly-on" && (
                        <div class="col-md-12 mb-4">
                          <figure className="calender-box">
                            <Calendar
                              value={values.Processing_time?.time_dates}
                              onChange={(e) => {
                                const dates = e?.map((date_obj, index) => new Date(date_obj));
                                setFieldValue("Processing_time.time_dates", dates);
                              }}
                            />
                          </figure>
                          <span class="validation-error">{errors.Processing_time?.time_dates}</span>
                        </div>
                      )}
                      {values.Processing_time.time_type === "on-dates" && (
                        <div class="col-md-12 mb-4">
                          <figure className="calender-box">
                            <Calendar
                              value={values.Processing_time?.time_dates}
                              onChange={(e) => {
                                const dates = e?.map((date_obj, index) => new Date(date_obj));
                                setFieldValue("Processing_time.time_dates", dates);
                              }}
                            />
                          </figure>
                          <span class="validation-error">{errors.Processing_time?.time_dates}</span>
                        </div>
                      )}
                      {values.Processing_time.time_type === "on-days" && (
                        <div class="accordion opening-hours" id="accordionExample">
                          <label class="form-label mb-3">On Days</label>

                          <FieldArray
                            name="Processing_time.time_days"
                            render={(dayHelpers) => (
                              <>
                                {values.Processing_time.onDays.map((days, key) => (
                                  <div class="accordion-item" key={key}>
                                    <h2 class="accordion-header">
                                      <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${key}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse${key}`}>
                                        <label class="switch-custom sm-switch">
                                          <input
                                            type="checkbox"
                                            name={`Processing_time.onDays.${key}.available`}
                                            onChange={(e) => {
                                              let { value, checked } = e.target;
                                              if (checked) {
                                                setFieldValue(`Processing_time.onDays.${key}.available`, true);
                                              } else {
                                                setFieldValue(`Processing_time.onDays.${key}.available`, false);
                                              }
                                            }}
                                          />

                                          <span class="slider round"></span>
                                        </label>
                                        {days.day}
                                      </button>
                                    </h2>
                                    <span class="validation-error">
                                      <ErrorMessage name={`Processing_time.onDays.${key}.available`} />
                                    </span>
                                    <div id={`collapse${key}`} class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                      <div class="accordion-body">
                                        <hr class="divider-accordion" />
                                        <div class="choose-day-select-box d-flex mb-3">
                                          <TimePicker
                                            placeholder="10:00"
                                            showSecond={false}
                                            focusOnOpen={true}
                                            onChange={(e) => {
                                              const time =
                                      new Date(e)
                                        .getHours()
                                        .toString()
                                        .padStart(2, "0") +
                                      ":" +
                                      new Date(e)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");
                                              setFieldValue(`Processing_time.onDays.${key}.open_time`, time);
                                            }}
                                          />
                                          <span class="validation-error">{errors?.Processing_time?.onDays?.[key]?.open_time}</span>
                                          <TimePicker
                                            class="form-select ms-3"
                                            placeholder="21:00"
                                            showSecond={false}
                                            focusOnOpen={true}
                                            onChange={(e) => {
                                              const time =
                                      new Date(e)
                                        .getHours()
                                        .toString()
                                        .padStart(2, "0") +
                                      ":" +
                                      new Date(e)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");
                                              setFieldValue(`Processing_time.onDays.${key}.close_time`, time);
                                            }}
                                          />
                                          <span class="validation-error">{errors?.Processing_time?.onDays?.[key]?.close_time}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          />
                        </div>
                      )}
                      {(values.Processing_time.time_type === "daily" ||
                        values.Processing_time.time_type === "days" ||
                        values.Processing_time.time_type === "on-dates") && (
                        <div class="row">
                          <div class="col-md-6 time-select">
                            <TimePicker
                              placeholder="10:00"
                              showSecond={false}
                              focusOnOpen={true}
                              onChange={(e) => {
                                const time =
                                      new Date(e)
                                        .getHours()
                                        .toString()
                                        .padStart(2, "0") +
                                      ":" +
                                      new Date(e)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");
                                setFieldValue("Processing_time.from_time1", time);
                              }}
                            />

                            {/* <span class="validation-error">{errors.Processing_time?.from_time1}</span> */}
                          </div>

                          <div class="col-md-6 time-select">
                            <TimePicker
                              placeholder="21:00"
                              showSecond={false}
                              focusOnOpen={true}
                              onChange={(e) => {
                                const time =
                                      new Date(e)
                                        .getHours()
                                        .toString()
                                        .padStart(2, "0") +
                                      ":" +
                                      new Date(e)
                                        .getMinutes()
                                        .toString()
                                        .padStart(2, "0");
                                setFieldValue("Processing_time.to_time1", time);
                              }}
                            />

                            <span class="validation-error">{errors.Processing_time?.to_time1}</span>
                          </div>
                        </div>
                      )}

                      <div class="btn-group-box mt-5 d-flex justify-content-center">
                        <button type="submit" disabled={isSubmitting} class="btn btn-primary">
                          {" "}
                          Add
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSet;
