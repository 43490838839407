import React, { useState } from 'react';
import './ContentSlider.css';

const ContentSlider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="content-slider">
      <button className="prev" onClick={prevSlide}>
      <i class="ri-arrow-left-s-line"></i>
      </button>
      {items.map((item, index) => (
        <div
          key={index}
          className={
            index === currentIndex
              ? 'slide slide-active'
              : 'slide slide-inactive'
          }
        >
          {item}
        </div>
      ))}
      <button className="next" onClick={nextSlide}>
      <i class="ri-arrow-right-s-line"></i>
      </button>
    </div>
  );
};

export default ContentSlider;