import React, { useEffect, useState } from 'react';
import axios from "axios";
import * as constant from '../constant';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import { Link, redirect, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../store/slice/authSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";


function LoginPage() {

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const schema = yup.object({
      email: yup.string()
         .email("Enter valid email")
         .required("Email is required"),
      password: yup
         .string()
         .required("Password is required"),
   });

   const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
   });

   const [passwordshow, setPasswordshow] = useState(false);
   const passwordHandler = () => {
      setPasswordshow(!passwordshow);
   }

   const onSubmit = async (formData, e) => {

      try {

         const response = await axios.post(constant.VENDOR_API_URL + "/login", formData);

         if (response.data.status) {

            localStorage.setItem("token", response.data.data.token);
            dispatch(login(response.data.data.token));

            toast.success(response.data.message, {
               position: toast.POSITION.TOP_RIGHT
            });
            e.target.reset();
            navigate("/dashboard");

         } else {
            toast.error(response.data.message, {
               position: toast.POSITION.TOP_RIGHT
            });
         }
      }
      catch (error) {
         toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
         });

      }


   }


   return (
      <div className="wraper-inner">
         <section className="accounts-page">
            <div className="container-fluid">
               <div className="accounts-page-box row">
                  <div className="col-md-7 accounts-page-box-left">
                     <Link to="/"> <figure className="pt-4"><img src="images/Eats_logo.png" /></figure></Link>
                     <div className="accounts-page-box-inner-L">
                        <div className="common-bg-form">
                           <div className="top-from-headning text-center mb-5">
                              <h3>Log in to Eats</h3>
                              <p>Log in to existing account with email address</p>
                           </div>
                           <form onSubmit={handleSubmit(onSubmit)} className="form-box login-form">
                              <ToastContainer />
                              <div className="form-group mb-4">
                                 <label className="form-label">Email</label>
                                 <input type="text" placeholder="Michael@abc.com" className="form-control" {...register("email")} />
                                 {errors.email && <span className="validation_error">{errors.email?.message}</span>}
                              </div>
                              <div className="form-group">
                                 <label className="form-label">Password</label>
                                 <div className="with-icon">
                                    <input type={passwordshow ? "text" : "password"} placeholder="Enter Password" className="form-control" {...register("password")} />
                                    <div className="icon-box"><i className={passwordshow ? "ri-eye-line" : "ri-eye-off-line"} onClick={passwordHandler}></i></div>
                                    {errors.password && <span className="validation_error">{errors.password?.message}</span>}
                                 </div>
                              </div>
                              <div className="btn-box text-center mt-4"><button type="submit" className="btn btn-primary mt-2">Login</button></div>
                              <Link to="/forgot-password" className="forget-password mt-3 d-block text-center">Forgot Password?</Link>
                           </form>
                        </div>
                     </div>
                     <div className="not-reg bottom-text text-center">
                        Don't have an account? <a href="/sign-up"><b>Sign up here</b></a>
                     </div>
                     <figure className="shape-1"><img src="images/shape-img.png" /></figure>
                  </div>
                  <div className="accounts-page-box-right col-md-5">
                     <a href="index.php">
                        <figure><img src="images/Eats_logo-w.png" /></figure>
                     </a>
                     <div className="accounts-page-box-inner-R">
                        <div className="account-content">
                           <h3>Turn Your Passion Into Profit</h3>
                           <p>Treat the world to what deliciousness you’re whipping up! We enthusiastically invite the entire food and beverage community, from chefs, farms, supermarkets, and restaurants to food trucks, hot dog stands, even artisanal pickle makers, and more! We provide you with all the tools you need to operate a thriving business, tailored to your unique vision.  Join the feast! </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>

   );

}

export default LoginPage;
