import React, { useState } from 'react';
import axios from "axios";
import * as constant from '../constant';
import Sidebar from '../components/Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet } from 'react-router-dom';

import { useSelector } from "react-redux";


function DashboardLayout() {
   const title = useSelector((state) => state.dashboardHeader.title);
   return (
      <section className="dash-main">
         <Sidebar />
         <div className="dash-main-R">
            <div className="dash-side-R-head d-flex justify-content-between align-items-center">
               <div className="dash-side-R-head-left">
                  <h2>{title}</h2>
               </div>
               <div className="dash-side-R-head-right d-flex align-items-center">
                  <div className="dropdown profile-drop">
                     <button className=" dropdown-toggle d-flex align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <figure> <img src="/images/profile-img.png" /></figure>
                     </button>
                  </div>
               </div>
            </div>
            <Outlet />
         </div>
      </section>
   );

}

export default DashboardLayout;
