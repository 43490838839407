import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../store/slice/dashboardHeaderSlice";
function FoodCommunities() {
    const dispatch = useDispatch();
      dispatch(setHeaderTitle("Food Communities"));
    return (
        <div>FoodCommunities</div>
    )
}

export default FoodCommunities 