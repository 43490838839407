import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { token: '' },
  reducers: {
    login(state, action) {
      return void (state.token = action.payload);
    },
    logout(state) {
      return void (state.token = '');
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;