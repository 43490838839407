import React, { Component, useState } from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Header() {

   const [isActive, setActive] = useState('about-us');


   return (

      <header className="topHeader" id="fixed-header">
         <div className="container">
            <nav className="navbar navbar-expand-lg " id="navbar-scrollspy">
               <div className="navbar-inner-box d-flex align-items-center justify-content-between">
                  <div className="nav-L d-flex">  <Link to="/"><img src="images/Eats_logo.png" /></Link></div>
                  <div className="nav-R ">
                     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><i className="ri-menu-3-line"></i>
                        </span>
                     </button>
                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                           <li className="nav-item">
                              <HashLink to="/#about-us" className={`nav-link  ${isActive == 'about-us' && 'active'}`} onClick={() => setActive('about-us')} aria-current="page" >About Us </HashLink>
                           </li>
                           <li className="nav-item">
                              <HashLink to="/#join-vendor" className={`nav-link ${isActive == 'vendor' && 'active'}`} onClick={() => setActive('vendor')} aria-current="page" >For Vendors</HashLink>
                           </li>
                           <li className="nav-item">
                              <HashLink to="/#video-box" className={`nav-link ${isActive == 'video' && 'active'}`} onClick={() => setActive('video')} aria-current="page" >Video</HashLink>
                           </li>
                           <li className="nav-item">
                              <HashLink to="/#faq" className={`nav-link ${isActive == 'faq' && 'active'}`} onClick={() => setActive('faq')} aria-current="page"  >FAQ</HashLink>
                           </li>
                           <li className="nav-item">
                              <HashLink to="/#testimonials" className={`nav-link ${isActive == 'testimonial' && 'active'}`} onClick={() => setActive('testimonial')} aria-current="page"  > Testimonials</HashLink>
                           </li>
                           <li className="nav-item">
                              <HashLink to="/#download-app" className={`nav-link ${isActive == 'app' && 'active'}`} onClick={() => setActive('app')} aria-current="page" >Download App</HashLink>
                           </li>
                           <li className="nav-item">
                              <HashLink to="/#contact-us" className={`nav-link ${isActive == 'contact' && 'active'}`} onClick={() => setActive('contact')} aria-current="page" > Contact Us</HashLink>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
      </header>

   );
}



export default Header;