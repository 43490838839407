import React from 'react';
import axios from "axios";
import * as constant from '../constant';
import 'react-toastify/dist/ReactToastify.css';
import ContactForm from '../components/ContactForm';


function HelpPage() {


   return (
      <div className="wraper-inner  cpt">
         <section className="help-box-main section-padding">
            <div className="container">
               <div className="section-head text-center mb-5">
                  <h2>Help</h2>
               </div>
               <div className="help-sec">
                  <span className="help-sec-shape"><img src="./images/banner-shape-1.png" /></span>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-md-5 me-auto help-sec-L">
                           <figure><img src="images/help-img.png" /></figure>
                        </div>
                        <ContactForm></ContactForm>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   );
}

export default HelpPage;
