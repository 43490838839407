import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constant from "../../constant";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { Link, redirect, useNavigate } from "react-router-dom";
import * as yup from "yup";
import StepBar from "./StepBar";
import { Formik, Form, Field } from "formik";
import Modal from "react-bootstrap/Modal";

function Step3({ setStep }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [file, setFiles] = useState([]);
  const [placeHolderFile, setPlaceHolderFile] = useState("Upload Document");

  function Step3Popup(props) {
    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
        <div className="modal-head d-flex align-items-center justify-content-center">
          <h1 className="modal-title" id="exampleModalLabel">
            Third Step Completed.
          </h1>
        </div>
        <div className="modal-body">
          <p className="text-center">Yay!! You have completed the third step.</p>
        </div>
        <div className="bottom-modal-footer text-center mt-3">
          <button
            type="button"
            onClick={() => {
              {
                props.onHide();
              }
              {
                props.step();
              }
            }}
            className="btn btn-primary">
            Continue
          </button>
        </div>
      </Modal>
    );
  }

  const schema = yup.object({
    documents: yup
      .mixed()
      .required("Document is required")
      .test("is-present", "Document must be less than five", (value) => value && value.length < 6),
  });

  return (
    <>
      <Formik
        initialValues={{
          documents: [],
        }}
        validationSchema={schema}
        onSubmit={async (data) => {
          let token = localStorage.getItem("token")  ?? null;

          const formData = new FormData();

          let documents = data.documents;

          for (let i = 0; i < documents.length; i++) {
            formData.append("documents", documents[i]);
          }

          try {
            const response = await axios.put(constant.VENDOR_API_URL + "/uploadDocuments", formData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.data.status) {
              setModalShow(true);
            } else {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setValues,
          setFieldError,
          /* and other goodies */
        }) => (
          <Form className="form-box login-form">
            <div className="form-box signup-form col-md-8 m-auto">
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="form-label">Documents</label>
                  <div className="file-upload-wrapper" data-text={placeHolderFile}>
                    <input
                      type="file"
                      className="file-upload-field"
                      placeholder="Upload Document"
                      onChange={(event) => {
                        var file = event.target.files;
                        for (let i = 0; i < file.length; i++) {
                          if (
                            file[i].type == "image/png" ||
                            file[i].type == "image/jpg" ||
                            file[i].type == "image/jpeg" ||
                            file[i].type == "application/pdf" ||
                            file[i].type == "application/msword"
                          ) {
                            if (file[i].size <= 3 * 1024 * 1024) {
                              setFiles(file);
                              setPlaceHolderFile(file[i].name);
                              setValues({ ...values, documents: file });
                            } else {
                              setFieldError("documents", "Size must be less than 1 MB");
                            }
                          } else {
                            setFieldError("documents", "Invalid File Format.");
                          }
                        }
                      }}
                      multiple
                      accept="image/png, image/jpeg,image/jpg,application/pdf,application/msword"
                    />
                    <button type="button" className="upload-btn">
                      <i className="ri-upload-line"></i>
                    </button>
                  </div>
                  {errors.documents && <span className="validation_error">{errors.documents}</span>}

                  <div className="upload-docouments-list mt-2">
                    <ul>
                      {Array.from(values.documents).map((file, index) => {
                        return (
                          <li key={index}>
                            {file.name}
                            <a
                              onClick={() => {
                                const fileListArr = [...values.documents];
                                fileListArr.splice(index, 1);

                                // let file_array = Array.from(values.documents);
                                // file_array.splice(index, 1);
                                setPlaceHolderFile("Upload Document");
                                setValues({ ...values, documents: fileListArr });
                              }}>
                              ×
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-box text-center mt-4">
              <Link
                to="login"
                onClick={() => {
                  sessionStorage.removeItem("step");
                }}
                type="button"
                className="btn btn-outline mt-2">
                Skip for Later
              </Link>
              <button type="submit" className="btn btn-primary mt-2 ms-3">
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Step3Popup
        show={modalShow}
        onHide={() => setModalShow(false)}
        step={() => {
          setStep(4);
        }}
      />
    </>
  );
}

export default Step3;
