import React from "react";
import axios from "axios";
import SimpleSlider from "../components/slider";
import ContactForm from "../components/ContactForm";
import * as constant from "../constant";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const parse = require("html-react-parser");

function HomePage() {
  const navigate = useNavigate();

  const [faqList, setFaq] = React.useState([]);
  const [Testimonials, setTestimonials] = React.useState([]);
  const [HomeData, setH1Data] = React.useState({
    H1: "",
    DescriptionH1: "",
    H2: "",
    DescriptionH2: "",
    H3: "",
    DescriptionH3: "",
    H4: "",
    DescriptionH4: "",
    H5: "",
    DescriptionH5: "",
  });

  React.useEffect(() => {
    axios.get(constant.API_URL + "/get_site_setting").then((response) => {
      setH1Data({
        H1: response.data.data.H1,
        DescriptionH1: response.data.data.descriptionH1,
        H2: response.data.data.H2,
        DescriptionH2: response.data.data.descriptionH2,
        H3: response.data.data.H3,
        DescriptionH3: response.data.data.descriptionH3,
        H4: response.data.data.H4,
        DescriptionH4: response.data.data.descriptionH4,
        H5: response.data.data.H5,
        DescriptionH5: response.data.data.descriptionH5,
      });
    });

    axios.get(constant.API_URL + "/get_testimonials").then((response) => {
      setTestimonials(response.data.data);
    });

    axios.get(constant.API_URL + "/get_faq").then((response) => {
      setFaq(response.data.data);
    });
  }, []);

  return (
    <div className="wraper-inner cpt">
      {/*banner-section*/}
      <section className="banner-sec">
        <span className="banner-shape-L">
          <img src="images/banner-shape-1.png" />
        </span>
        <span className="banner-shape-R">
          <img src="images/banner-shape-2.svg" />
        </span>
        <div className="container">
          <div className="row ">
            <div className="col-md-6 banner-sec-L pt-5">
              <h1>{HomeData.H1} </h1>
              <p>{parse(HomeData.DescriptionH1)}</p>
              <a className="btn btn-primary mt-4">Know More</a>
            </div>
            <div className="col-md-6 banner-sec-R">
              <figure>
                <img src="images/eats-mob-img.svg" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/*fav-dishes*/}
      <section className="fav-dishes  section-padding bg-black" id="about-us">
        <div className="container">
          <div className="row row-cols-2 fav-dishes-head mb-5 align-items-center">
            <div className="cols pe-5 fav-dishes-head-L">
              <div className="section-head">
                <h2 className="text-white">{HomeData.H2} </h2>
              </div>
            </div>
            <div className="cols ps-5 fav-dishes-head-R">
              <div className="section-head ps-5">
                <p className="text-white">{parse(HomeData.DescriptionH2)}</p>
              </div>
            </div>
          </div>
          <div className="row row-cols-4 fav-dishes-img">
            <div className="cols">
              <figure>
                <img src="images/fav-dishes-img-1.png" />
              </figure>
            </div>
            <div className="cols">
              <figure>
                <img src="images/fav-dishes-img-2.png" />
              </figure>
            </div>
            <div className="cols">
              <figure>
                <img src="images/fav-dishes-img-3.png" />
              </figure>
            </div>
            <div className="cols">
              <figure>
                <img src="images/fav-dishes-img-4.png" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/*join-vendor*/}
      <section className="join-vendor section-padding " id="join-vendor">
        <span className="join-vendor-shape-l">
          <img src="images/join-vendor-img-1.png" />
        </span>
        <span className="join-vendor-shape-r">
          <img src="images/banner-shape-1.png" />
        </span>
        <div className="container">
          <div className="row row-cols-2  align-items-center">
            <div className="cols join-vendor-L">
              <figure>
                <img src="images/join-vendor-img.png" />
              </figure>
            </div>
            <div className="cols join-vendor-R ps-0 pe-5">
              <div className="section-head">
                <h2 className="mb-3">{HomeData.H3} </h2>
                <p>{parse(HomeData.DescriptionH3)}</p>

                <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    navigate("/login");
                  }}>
                  Join as Vendor
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*video*/}
      <section className="video-box section-padding  p-0" id="video-box">
        <div className="container">
          <div className="wrapper-video">
            <img id="video-cover" src="images/video-img.png" alt="Video title" />
            <iframe
              id="player"
              width="100%"
              height="550"
              src="https://www.youtube.com/embed/d7lCZ0rpH3o"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen></iframe>
            <button id="play" className="play-btn">
              <i className="ri-play-fill"></i>
              <span></span>
            </button>
          </div>
        </div>
      </section>
      {/*faq*/}
      <section className="faq-sec section-padding" id="faq">
        <div className="container">
          <div className="section-head mb-5 text-center">
            <h2>Frequently Asked Questions </h2>
          </div>
          <div className="faq-inner">
            <div className="accordion" id="accordionExample">
              {faqList.map((faq, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={"heading" + index}>
                    <button
                      className={"accordion-button" + (index == 0 ? "" : " collapsed")}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + index}
                      aria-expanded="true"
                      aria-controls={"collapse" + index}>
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={"collapse" + index}
                    className={"accordion-collapse collapse " + (index == 0 ? "show" : "")}
                    aria-labelledby={"heading" + index}
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <p> {faq.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/*Testimonials*/}

      <section className="Testimonials  bg-black" id="testimonials">
        <span className="tetimonail-patterns-l">
          <img src="images/tetimonail-patterns.png" />
        </span>
        <span className="testimonials-green-box"></span>
        <div className="container">
          <div className="row ">
            <div className="col-md-5 Testimonials-l">
              <div className="section-head ">
                <h2 className="text-white">What are Customers are saying about Eats?</h2>
              </div>
            </div>
            <div className="col-md-6 ms-auto ps-4 Testimonials-r ">
              <div className="testimonial-slider ">
                <SimpleSlider testimonials={Testimonials}></SimpleSlider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*download-app*/}
      <section className="download-app section-padding pb-5" id="download-app">
        <span className="download-app-shape-l">
          <img src="images/banner-shape-1.png" />
        </span>
        <span className="download-app-shape-r">
          <img src="images/download-app-shape-img.svg" />
        </span>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 download-app-L">
              <span className="vector-img-top">
                <img src="images/pettern-img-vector.svg" />
              </span>
              <div className="section-head mb-4">
                <h2 className="mb-3">{HomeData.H4}</h2>
                <p>{parse(HomeData.DescriptionH4)}</p>
                <span className="vector-img-bottom">
                  <img src="images/pettern-img-vector.svg" />
                </span>
              </div>
              <div className="apps-store-sm d-flex">
                <a href="#" className="me-3">
                  <img src="images/app-store-sm.svg" />
                </a>
                <a href="#">
                  <img src="images/google-play-sm.svg" />
                </a>
              </div>
            </div>
            <div className="col-md-5 m-auto download-app-R ">
              <figure>
                <img src="images/download-app-img.png" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="connect-with section-padding  bg-grey" id="contact-us">
        <div className="container">
          <div className="row">
            <ContactForm></ContactForm>

            <div className="col-md-5 ms-auto connect-with-R">
              <figure>
                <img src="images/connect-img.png" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
