import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constant from "../../constant";
import { useParams } from "react-router-dom";



function AddMenuDetail() {
  // const [data, setData] = useState([]);
  // const [pageLoader,setPageLoader]=useState(true)
  // const { id } = useParams();
  // useEffect(() => {
  //   fetchData();
  // }, []);
  // const fetchData = async () => {
  //   try {
  //     let token = localStorage.getItem("token");
  //     const headers = { Authorization: "Bearer " + token };
  //     const response = await axios.get(constant.VENDOR_API_URL + "/getDisheDetails", { params: { id: id }, headers });
  //     setData(response.data.data);
  //     setPageLoader(false)
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //   }
  // };
 
  return (
    <div class="dash-side-R-body">
    <div class="row order-detail-list-box mt-4">
        <div class="col-md-12">
            <ul class="dish-number-list">
                <li class="d-flex">
                    <div class="dish-number-list-menu d-flex align-items-center">
                        <h4>Dishes</h4>
                    </div>
                    <div class="dish-number-list-menu d-flex align-items-center">
                        <h4>Number of Servings</h4>
                        <span class="tool-tip-box con-tooltip top">
                            <i class="ri-information-line"></i>
                            <div class="tooltip">
                                <p>Top</p>
                            </div>
                        </span>
                    </div>
                    <div class="dish-number-list-menu d-flex align-items-center">
                        <h4>Set Communities</h4>
                        <span class="tool-tip-box con-tooltip top">
                            <i class="ri-information-line"></i>
                            <div class="tooltip">
                                <p>Top</p>
                            </div>
                        </span>
                    </div>
                </li>
                <li class="d-flex align-items-center">
                    <div class="dish-number-list-box">
                        <div class="dishes-orders d-flex me-5">
                            <figure><img src="images/dishes-img-1.png" /></figure>
                            <figcaption class="ps-2">
                                <h4>Pizza</h4>
                                <p><b>Food Class:</b> Ingredients</p>
                            </figcaption>
                        </div>
                    </div>
                    <div class="dish-number-list-box">
                        <div class="number">
                            <span class="minus"><i class="ri-subtract-fill"></i></span>
                            <input type="text" value="1" />
                            <span class="plus"><i class="ri-add-line"></i></span>
                        </div>
                    </div>
                    <div class="dish-number-list-box"><a class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#manage-communities">Manage Communities</a></div>
                </li>
                <li class="d-flex align-items-center">
                    <div class="dish-number-list-box">
                        <div class="dishes-orders d-flex me-5">
                            <figure><img src="images/dishes-img-1.png" /></figure>
                            <figcaption class="ps-2">
                                <h4>Pizza</h4>
                                <p><b>Food Class:</b> Ingredients</p>
                            </figcaption>
                        </div>
                    </div>
                    <div class="dish-number-list-box">
                        <div class="number">
                            <span class="minus"><i class="ri-subtract-fill"></i></span>
                            <input type="text" value="1" />
                            <span class="plus"><i class="ri-add-line"></i></span>
                        </div>
                    </div>
                    <div class="dish-number-list-box"><a class="btn btn-primary">Manage Communities</a></div>
                </li>
                <li class="d-flex align-items-center">
                    <div class="dish-number-list-box">
                        <div class="dishes-orders d-flex me-5">
                            <figure><img src="images/dishes-img-1.png" /></figure>
                            <figcaption class="ps-2">
                                <h4>Pizza</h4>
                                <p><b>Food Class:</b> Ingredients</p>
                            </figcaption>
                        </div>
                    </div>
                    <div class="dish-number-list-box">
                        <div class="number">
                            <span class="minus"><i class="ri-subtract-fill"></i></span>
                            <input type="text" value="1" />
                            <span class="plus"><i class="ri-add-line"></i></span>
                        </div>
                    </div>
                    <div class="dish-number-list-box"><a class="btn btn-primary">Manage Communities</a></div>
                </li>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group mb-4 col-md-12 mb-4">
                            <div class="label-box d-flex justify-content-between">
                                <label class="form-label">Ordering Window</label>
                                <span class="tool-tip-box con-tooltip top">
                                    <i class="ri-information-line"></i>
                                    <div class="tooltip">
                                        <p>Top</p>
                                    </div>
                                </span>
                            </div>
                            <div class="radio-wrap d-flex align-items-center">
                                <label class="radio-coustom-design me-4 mb-0">
                                    <input type="radio" checked="checked" name="radio" />
                                    <span class="checkmark"></span>
                                    <p>Anytime</p>
                                </label>
                                <label class="radio-coustom-design me-4 mb-0">
                                    <input type="radio" checked="checked" name="radio" />
                                    <span class="checkmark"></span>
                                    <p>Weekly</p>
                                </label>
                                <label class="radio-coustom-design mb-0">
                                    <input type="radio" checked="checked" name="radio" />
                                    <span class="checkmark"></span>
                                    <p>Monthly</p>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="label-box d-flex justify-content-between">
                                <label class="form-label">Order Processing Time</label>
                                <span class="tool-tip-box con-tooltip top">
                                    <i class="ri-information-line"></i>
                                    <div class="tooltip">
                                        <p>Top</p>
                                    </div>
                                </span>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <a class="btn-show">Default: <span> Immediate 24/7</span></a>
                                    <div class="border-box mb-3">
                                        <div class="radio-wrap">
                                            <label class="radio-coustom-design me-4">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Immediate</p>
                                            </label>
                                            <label class="radio-coustom-design me-4">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Hours</p>
                                            </label>
                                            <label class="radio-coustom-design">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Daily</p>
                                            </label>
                                            <label class="radio-coustom-design">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Days</p>
                                            </label>
                                            <label class="radio-coustom-design">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>On Days</p>
                                            </label>
                                            <label class="radio-coustom-design">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>On Dates</p>
                                            </label>
                                        </div>
                                    </div>
                                    <label class="form-label">Batch Processed</label>
                                    <div class="border-box">
                                        <div class="radio-wrap">
                                            <label class="radio-coustom-design me-4">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Weekly On</p>
                                            </label>
                                            <label class="radio-coustom-design me-4">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Bi-Weekly On</p>
                                            </label>
                                            <label class="radio-coustom-design">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Monthly On</p>
                                            </label>
                                            <label class="radio-coustom-design">
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                                <p>Advance Order</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="text-show-pannel">
                                        <h4>Settings Guide</h4>
                                        <div class="text-show-white">
                                            <h5>Immediate Order (upon order)</h5>
                                            <p>
                                                Food is an essential part of our daily lives. It provides us with the energy and nutrients we need to stay healthy and active. There are many different types of food available, each
                                                with its own unique taste and nutritional benefits. Some foods are high in protein, such as meat, fish, and
                                            </p>
                                            <p>
                                                Food is an essential part of our daily lives. It provides us with the energy and nutrients we need to stay healthy and active. There are many different types of food available, each
                                                with its own unique taste and nutritional benefits. Some foods are high in protein, such as meat, fish, and beans, while others are rich
                                            </p>
                                            <div class="text-end">
                                                <a class="read-more"><img src="images/read-more-arrow.png" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
            <div class="btn-group-box mt-5">
                <a class="btn btn-primary">Save</a>
                <a class="btn btn-outline ms-2">Add More Dishes</a>
            </div>
        </div>
    </div>
</div>
  );
}
export default AddMenuDetail;
