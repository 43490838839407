import { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';
import setAuthToken from "../utils/setAuthToken"
// import { logout } from '../store/slice/authSlice';
const ProviderComponent = ({ children }) => {
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setAuthToken(token);
        }

        // store.dispatch(loadUser())
        window.addEventListener("storage", () => {
            // store.dispatch(logout());
        });
    }, []);

    return (
        <div>
            <Provider store={store}>
                {children}
            </Provider>
        </div>
    );
};

export default ProviderComponent;