import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { Outlet} from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop copy';


function FrontLayout() {

  return (
   <div>
   <ScrollToTop/>
    <Header/>
      <Outlet/>
    <Footer />
    </div>
  );
  
}

export default FrontLayout;
