import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./rootReducer";
import setAuthToken from '../utils/setAuthToken';

const redux_data = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : { "counter": 0, "auth": { "token": "" } }

let token = localStorage.getItem("token")
if (token) {
  setAuthToken(token)
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState: redux_data,
  devTools: true,
});


window.addEventListener('beforeunload', () => {

  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});


export default store;