import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../store/slice/dashboardHeaderSlice";
  
function FoodType() {
    const dispatch = useDispatch();
    dispatch(setHeaderTitle("Food Type"));
    return (
        <div className="dash-side-R-body">
       <div>dfgdsffgdshgdshgfdg</div>
       
         </div>
    )
}

export default FoodType