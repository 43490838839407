import React, { useEffect, useState } from "react";
import axios from "axios";
import * as constant from "../../constant";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { Link, redirect, useNavigate } from "react-router-dom";
import * as yup from "yup";
import StepBar from "./StepBar";
import { Formik, Form, Field } from "formik";
import Modal from "react-bootstrap/Modal";
import ContentSlider from '../../components/ContentSlider/ContentSlider';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../../store/slice/authSlice';

function Step4({ setStep }) {
   const [modalShow, setModalShow] = React.useState(false);
   const [ButtonText, setButtonText] = React.useState('Next');
   const [modalTutorialShow, setModalTutorialShow] = React.useState(false);
   const dispatch = useDispatch();
 
  function Step4Popup(props) {
    return (
  
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false} 
      >
  
        <div class="modal-head d-flex align-items-center justify-content-center">
           <h1 class="modal-title" id="exampleModalLabel">Successfully Registered</h1>
           {/* <button type="button"  onClick={()=>{setModalShow(false)}} class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="ri-close-fill"></i></button> */}
        </div>
        
        <div class="modal-body">
           <p class="text-center">Congratulations! You are registered as <br/>an Eats Vendor.</p>
           <h4 class="sub-title">Welcome!</h4>
        </div>

        <div class="bottom-modal-footer text-center mt-3">
           <button type="button" class="btn btn-primary"   onClick={()=>{setModalShow(false); setModalTutorialShow(true)}}>Continue</button>
        </div>


      </Modal>
    );
  }


  function StepTutorial(props) {

    const [tutorialList, setTutorialList] = React.useState([]);

    const getTutorials = async () => {
       let token = localStorage.getItem("token");
       const headers = { Authorization: "Bearer " + token };
       const response = await axios.post(constant.VENDOR_API_URL + "/getAllTutorial", {tutorial_type:'vendor'},{ headers });
   
       if (response.data.status) {

          let tutorialdata=response.data.data;

          let list=tutorialdata.map((item)=>{

           return <div className="slider-item">
             <img src={constant.BACKEND_URL + item.tutorial_image}></img>
             <h3 class="text-center mt-3">{item.title}</h3>
            <p class="text-center mt-3">{item.content}</p> 
             </div>
          });

          setTutorialList(list);
       } 
     };

  

    useEffect(() => {
       getTutorials();
     }, []);


    return (
  
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false} 
        >
  
  
       <div class="modal-head d-flex align-items-center justify-content-center">
          <h1 class="modal-title" id="exampleModalLabel">Eats Tutorial</h1>
          {/* <button type="button" onClick={()=>{setModalTutorialShow(false)}} class="btn-close" data-bs-dismiss="modal" aria-label="Close" ><i class="ri-close-fill"></i></button> */}
       </div>
       <div class="modal-body">
       <ContentSlider items={tutorialList} />
       </div>
       <div class="bottom-modal-footer text-center mt-3">
          <Link to="/dashboard" class="btn btn-primary">Continue</Link>
       </div>
    
      </Modal>
    );
  }


  const schema = yup.object().shape({
    account_holder_name: yup
       .string()
       .required("Bank Holder Name is required"),
    account_number: yup
       .string()
       .required("Account Number is required"),
    routing_number: yup
       .string()
       .required("Bank Routing Number is required"),
 });

  return (
    <>
      <Formik
        initialValues={{
          account_holder_name: "",
          account_number: "",
          routing_number: ""
        }}
        validationSchema={schema}
        onSubmit={async (data) => {

          setButtonText('Loading.. Please wait');

          let token = localStorage.getItem("token") ?? null;

 
          try {
            const response = await axios.post(constant.VENDOR_API_URL + "/addPaymentInfo", data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.data.status) {
              setButtonText('Next');
              dispatch(login(token));
              setModalShow(true);
            } else {
              setButtonText('Next');
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } catch (error) {
            setButtonText('Next');
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setValues,
          setFieldError,
          getFieldProps,
          /* and other goodies */
        }) => (
          <Form className="form-box login-form">
            
            <div className="form-box signup-form col-md-8 m-auto">
               <div className="row">
                  <div className="col-md-6">
                     <div className="form-group mb-4">
                        <label className="form-label"> Bank Account Holder Name</label>

                        <input type="text" placeholder="Enter  Bank account holder name" className="form-control"  onChange={handleChange}  {...getFieldProps("account_holder_name")}
                 />
                        <span class="validation-error">{errors.account_holder_name && touched.account_holder_name && errors.account_holder_name}</span>
                     </div>
                  </div>
   
                  <div className="col-md-6">
                     <div className="form-group mb-4">
                        <label className="form-label"> Bank Account Number</label>
                        <input type="text" placeholder="Enter  Bank account number" className="form-control"  onChange={handleChange}  {...getFieldProps("account_number")} />
                        
                        <span class="validation-error">{errors.account_number && touched.account_number && errors.account_number}</span>

                     </div>
                  </div>
   
                  <div className="col-md-6">
                     <div className="form-group mb-4">
                        <label className="form-label">Bank Routing Number</label>
                        <input type="text" placeholder="Enter Bank routing number" className="form-control" onChange={handleChange} {...getFieldProps("routing_number")} />
                        <span class="validation-error">{errors.routing_number && touched.routing_number && errors.routing_number}</span>
                     </div>
                  </div>
   
               </div>
            </div>

            <div className="btn-box text-center mt-4">
            <button type="button" onClick={()=>{  setModalShow(true);  
              
              let token = localStorage.getItem("token") ?? null;
              
              dispatch(login(token)); }}  className="btn btn-outline mt-2" >Skip and submit</button>
               <button type="submit"  className="btn btn-primary mt-2 ms-3" >{ButtonText}</button>
            </div>    

          </Form>
        )}
      </Formik>
      <Step4Popup show={modalShow} onHide={() => setModalShow(false)} />
      <StepTutorial show={modalTutorialShow} onHide={() => setModalTutorialShow(false)} />
    </>
  );
}

export default Step4;
