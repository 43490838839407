import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function VerificationPopup(props) {
  return (

    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >

      <div className="modal-head d-flex align-items-center justify-content-center">
        <h1 className="modal-title" id="exampleModalLabel">Link Sent Successfully</h1>
        <button type="button" className="btn-close" onClick={props.onHide} data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
      </div>
      <div className="modal-body">
        <p className="text-center">Password Reset Link sent successfully to <br />your email</p>
      </div>
      <div className="bottom-modal-footer text-center mt-3">
        <button type="button" onClick={props.onHide} className="btn btn-primary">Ok</button>
      </div>

    </Modal>
  );
}

export default VerificationPopup;