import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../store/slice/dashboardHeaderSlice";
function Settings() {
    const dispatch = useDispatch();
    dispatch(setHeaderTitle("Settings"));
    return (
        <div>Settings</div>
    )
}

export default Settings