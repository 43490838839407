import { createSlice } from '@reduxjs/toolkit';

const dashboardHeaderSlice = createSlice({
  name: 'dashboardHeader',
  initialState: { title: 'Dashboard' },
  reducers: {
    setHeaderTitle(state, action) {
      return void (state.title = action.payload);
    },
   
  },
});

export const { setHeaderTitle } = dashboardHeaderSlice.actions;
export default dashboardHeaderSlice.reducer;