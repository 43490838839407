import React, { Component } from "react";
import { Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Footer() {

   return (
    <footer className="bg-black" >
    <div className="footer-box">
       <div className="container">
          <div className="row">
             <div className="col-md-5 footer-content me-auto">
                <div className="footer-top-left">
                   <figure className="mb-4"><Link to="/"><img src="images/Eats_logo-footer.png"/></Link></figure>
                   <p className="text-white pe-5">Um vendedor é uma pessoa ou empresa que vende bens ou serviços. Eles podem variar de pequenos vendedores independentes a grandes corporações. Os fornecedores podem  </p>
                   <ul className="footer-link-terms d-flex align-items-center mt-3">
                      <li><Link to="/term-condition"> Terms &amp; Conditions</Link></li>
                      <li><Link to="/privacy-policy"> Privacy &amp; Policy</Link></li>
                   </ul>
                </div>
             </div>
             <div className="col-md-3 ms-auto footer-content">
                <h3>Sitemap</h3>
                <ul className="footer-link">
                   <li><HashLink to="/#about-us">About Us </HashLink></li>
                   <li><Link to="help">Help</Link></li>
                   <li><HashLink to="/#faq">FAQ’s</HashLink></li>
                   <li><HashLink to="/#contact-us">Contact Us</HashLink></li>
                </ul>
             </div>
             <div className="col-md-3 footer-content">
                <h3>Download App</h3>
 
                <div className="apps-store">
                   <a href="#" className="mb-2"><img src="images/app-store-btn.svg"/></a>
                   <a href="#"><img src="images/google-play-btn.svg"/></a>
                </div>
             </div>
          </div>
 
 
 
          <div className="bottom-footer text-center">
             <p>Copyright © 2022-2023 Eats</p>
          </div>
       </div>
    </div>
 </footer>
    );
  }



export default Footer;