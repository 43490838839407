import React from 'react';
import axios from "axios";
import * as constant from '../constant';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { setHeaderTitle } from '../store/slice/dashboardHeaderSlice';

function Dashboard() {

   const dispatch = useDispatch();
   dispatch(setHeaderTitle("Dashboard"));

   return (
      <div className="dash-side-R-body">
         <div className="row row-cols-5 dash-box-main mb-4">
            <div className="col dash-box-R-box">
               <div className="box-shadow-box">
                  <div className="dash-title-head d-flex justify-content-between align-items-center mb-0 h-100">
                     <div className="dash-title-head-l">
                        <h4>Total dishes served</h4>
                        <span className="dash-amount">1,500</span>
                     </div>
                     <div className="dash-title-head-r">
                        <span className="icon-circle"><img src="/images/d-1.png" /></span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col dash-box-R-box">
               <div className="box-shadow-box green-box">
                  <div className="dash-title-head d-flex justify-content-between align-items-center mb-0 h-100">
                     <div className="dash-title-head-l">
                        <h4>Total sales</h4>
                        <span className="dash-amount">$1,500</span>
                     </div>
                     <div className="dash-title-head-r">
                        <span className="icon-circle"><img src="images/d-2.png" /></span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col dash-box-R-box">
               <div className="box-shadow-box brown-box">
                  <div className="dash-title-head d-flex justify-content-between align-items-center mb-0 h-100">
                     <div className="dash-title-head-l">
                        <h4>Total Refund</h4>
                        <span className="dash-amount">$600</span>
                     </div>
                     <div className="dash-title-head-r">
                        <span className="icon-circle"><img src="images/d-3.png" /></span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col dash-box-R-box">
               <div className="box-shadow-box  pink-box">
                  <div className="dash-title-head d-flex justify-content-between align-items-center mb-0 h-100">
                     <div className="dash-title-head-l">
                        <h4>Discount</h4>
                        <span className="dash-amount">$1,000</span>
                     </div>
                     <div className="dash-title-head-r">
                        <span className="icon-circle"><img src="images/d-3.png" /></span>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col dash-box-R-box">
               <div className="box-shadow-box  blue-box">
                  <div className="dash-title-head d-flex justify-content-between align-items-center mb-0 h-100">
                     <div className="dash-title-head-l">
                        <h4>Discount</h4>
                        <span className="dash-amount">$1,000</span>
                     </div>
                     <div className="dash-title-head-r">
                        <span className="icon-circle"><img src="images/d-5.png" /></span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <h3 className="dash-title">Active Orders</h3>
         <div className="table-box">
            <table className="table border-table">
               <tbody>
                  <tr>
                     <th>Date</th>
                     <th>Order id</th>
                     <th>Dishes Ordered</th>
                     <th>Delivery preference</th>
                     <th>Customer name</th>
                     <th>Order instructions</th>
                     <th>Action</th>
                  </tr>
               </tbody>
               <tbody>
                  <tr>
                     <td>10-12-2023</td>
                     <td>#234234</td>
                     <td>
                        <div className="dishes-orders d-flex mb-3">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                        <div className="dishes-orders d-flex">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                     </td>
                     <td>Delivery</td>
                     <td>Clare T. Shier</td>
                     <td>Lorem ipsum sit amet</td>
                     <td>
                        <div className="d-flex action-main">
                           <a className="action-box"><i className="ri-check-line"></i></a>
                           <a className="action-box delete-action ms-2"><i className="ri-close-line"></i></a>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td>10-12-2023</td>
                     <td>#234234</td>
                     <td>
                        <div className="dishes-orders d-flex mb-3">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                        <div className="dishes-orders d-flex">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                     </td>
                     <td>Delivery</td>
                     <td>Clare T. Shier</td>
                     <td>Lorem ipsum sit amet</td>
                     <td>
                        <div className="d-flex action-main">
                           <a className="action-box"><i className="ri-check-line"></i></a>
                           <a className="action-box delete-action ms-2"><i className="ri-close-line"></i></a>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td>10-12-2023</td>
                     <td>#234234</td>
                     <td>
                        <div className="dishes-orders d-flex mb-3">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                        <div className="dishes-orders d-flex">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                     </td>
                     <td>Delivery</td>
                     <td>Clare T. Shier</td>
                     <td>Lorem ipsum sit amet</td>
                     <td>
                        <div className="d-flex action-main">
                           <a className="action-box"><i className="ri-check-line"></i></a>
                           <a className="action-box delete-action ms-2"><i className="ri-close-line"></i></a>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td>10-12-2023</td>
                     <td>#234234</td>
                     <td>
                        <div className="dishes-orders d-flex mb-3">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                        <div className="dishes-orders d-flex">
                           <figure><img src="images/dishes-img-1.png" /></figure>
                           <figcaption className="ps-2">
                              <h4>Pizza</h4>
                              <p><b>Food  Class:</b> Ingredients</p>
                              <p><b>Qty:</b>02</p>
                           </figcaption>
                        </div>
                     </td>
                     <td>Delivery</td>
                     <td>Clare T. Shier</td>
                     <td>Lorem ipsum sit amet</td>
                     <td>
                        <div className="d-flex action-main">
                           <a className="action-box"><i className="ri-check-line"></i></a>
                           <a className="action-box delete-action ms-2"><i className="ri-close-line"></i></a>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );

}

export default Dashboard;
