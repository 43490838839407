import React from 'react';
import axios from "axios";
import * as constant from '../constant';
import 'react-toastify/dist/ReactToastify.css';
const parse = require('html-react-parser');


function PrivacyPolicyPage() {

   const [content, seContent] = React.useState('');


   React.useEffect(() => {

      axios.get(constant.API_URL + "/privacy_policy").then((response) => {
         seContent(response.data.data.content);
      });

   }, []);

   return (
      <div className="wraper-inner  cpt">
         <section className="terms-box section-padding">
            <div className="container">
               <div className="section-head text-center mb-5">
                  <h2 >Privacy Policy</h2>
               </div>
               <div className="row">
                  <div className="col-md-11 m-auto">
                     {parse(content)}
                  </div>
               </div>
            </div>
         </section>
      </div>
   );
}

export default PrivacyPolicyPage;
