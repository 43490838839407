import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setHeaderTitle } from "../store/slice/dashboardHeaderSlice";
 
function MyReviews() {
    const dispatch = useDispatch();
    dispatch(setHeaderTitle("My Reviews"));
    return (
        <div>MyReviews</div>
    )
}

export default MyReviews