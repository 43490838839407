import React, { useState } from 'react';
import axios from "axios";
import * as constant from '../constant';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link } from 'react-router-dom';


function ResetPasswordPage() {

   const [passwordshow, setPasswordshow] = useState({ password: false, confirm_password: false });
   const passwordHandler = (type) => {
      if (type == 'password') {
         setPasswordshow({ ...passwordshow, password: !passwordshow.password });
      }
      if (type == 'confirm_password') {
         setPasswordshow({ ...passwordshow, confirm_password: !passwordshow.confirm_password });
      }

   }

   const navigate = useNavigate();

   const schema = yup.object({
      password: yup
         .string()
         .required("Password is required")
         .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, { message: "Passwords should be atleast 8 characters with uppercase letters, lowercase letters and at least one number." }),

      confirm_password: yup
         .string()
         .oneOf([yup.ref("password"), null], "Passwords must match")
         .required("Confirm Password is required"),
   });


   const [searchParams, setSearchParams] = useSearchParams();
   const token = searchParams.get("token");
   const { register, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
   });
   const onSubmit = async (formData, e) => {
      formData.token = token;
      try {
         const response = await axios.post(constant.VENDOR_API_URL + "/resetPassword", formData);

         if (response.data.status) {
            toast.success(response.data.message, {
               position: toast.POSITION.TOP_RIGHT
            });
            e.target.reset();
            navigate('/login');

         } else {
            toast.error(response.data.message, {
               position: toast.POSITION.TOP_RIGHT
            });
         }
      }

      catch (error) {
         toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT
         });

      }

   }


   return (
      <div className="wraper-inner">
         <section className="accounts-page">
            <div className="container-fluid">
               <div className="accounts-page-box row">
                  <div className="col-md-7 accounts-page-box-left">
                     <Link to="/"> <figure className="pt-4"><img src="images/Eats_logo.png" /></figure></Link>
                     <div className="accounts-page-box-inner-L">
                        <div className="common-bg-form">
                           <div className="top-from-headning text-center mb-5">
                              <h3>Reset Password</h3>
                              <p>Please enter the new password</p>
                           </div>
                           <form className="form-box login-form" onSubmit={handleSubmit(onSubmit)} >
                              <ToastContainer />
                              <div className="form-group mb-4">
                                 <label className="form-label">New Password</label>
                                 <div className="with-icon">
                                    <input type={passwordshow.password ? "text" : "password"} placeholder="Enter Password" className="form-control" {...register("password")} />
                                    <div className="icon-box" >{passwordshow.password ? <i className="ri-eye-line" onClick={() => passwordHandler('password')}></i> : <i className="ri-eye-off-line" onClick={() => passwordHandler('password')}></i>}</div>
                                    {errors.password && <span className="validation_error">{errors.password?.message}</span>}
                                 </div>
                              </div>
                              <div className="form-group">
                                 <label className="form-label">Confirm New Password</label>
                                 <div className="with-icon">
                                    <input type={passwordshow.confirm_password ? "text" : "password"} placeholder="Confirm Password" className="form-control" {...register("confirm_password")} />
                                    <div className="icon-box">{passwordshow.confirm_password ? <i className="ri-eye-line" onClick={() => passwordHandler('confirm_password')}></i> : <i className="ri-eye-off-line" onClick={() => passwordHandler('confirm_password')}></i>}</div>
                                    {errors.confirm_password && <span className="validation_error">{errors.confirm_password?.message}</span>}
                                 </div>
                              </div>
                              <div className="btn-box text-center mt-4"><button type="submit" className="btn btn-primary mt-2">Reset</button></div>

                           </form>
                        </div>
                     </div>

                     <figure className="shape-1"><img src="images/shape-img.png" /></figure>
                  </div>
                  <div className="accounts-page-box-right col-md-5">
                     <a href="index.php">
                        <figure><img src="images/Eats_logo-w.png" /></figure>
                     </a>
                     <div className="accounts-page-box-inner-R">
                        <div className="account-content">
                           <h3>Turn Your Passion Into Profit</h3>
                           <p>Treat the world to what deliciousness you’re whipping up! We enthusiastically invite the entire food and beverage community, from chefs, farms, supermarkets, and restaurants to food trucks, hot dog stands, even artisanal pickle makers, and more! We provide you with all the tools you need to operate a thriving business, tailored to your unique vision.  Join the feast! </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   );

}

export default ResetPasswordPage;
