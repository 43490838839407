import React, { Component } from "react";
import * as constant from '../constant';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";



function ContactForm() {

  const schema = yup.object({
    name: yup
      .string()
      .matches(/^\s*\S[\s\S]*$/, { message: "Name is required" })
      .required("Name is required"),
    email: yup.string().email().required("Email is required"),
    subject: yup
      .string()
      .matches(/^\s*\S[\s\S]*$/, { message: "Subject is required" })
      .required("Subject is required"),
    feedback: yup
      .string()
      .matches(/^\s*\S[\s\S]*$/, { message: "Feedback is required" })
      .required("Feedback is required"),


  });


  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = async (formData, e) => {

    const response = await axios.post(constant.API_URL + "/savefeedback", formData);

    if (response.data.status) {
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      e.target.reset();
    } else {
      toast.error(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }


  };




  return (
    <div className="col-md-6 connect-with-L">

      <form onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer />
        <div className="section-head mb-4">
          <h2>Connect with us with your feedback</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Name</label>
            <input type="text" className="form-control" placeholder="Enter name" {...register("name", { required: true, maxLength: 100 })} />
            {errors.name && <span className="validation_error">Name is required</span>}
          </div>
          <div className="col-md-6">
            <label className="form-label">Email </label>
            <input type="text" className="form-control" placeholder="Enter email"  {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
            {errors.email && <span className="validation_error">Email is required</span>}
          </div>
          <div className="col-md-12 mt-3">
            <label className="form-label">Subject </label>
            <input type="text" className="form-control" placeholder="Enter subject" {...register("subject", { required: true })} />
            {errors.subject && <span className="validation_error">Subject is required</span>}
          </div>
          <div className="col-md-12 mt-3">
            <label className="form-label">Write Feedback</label>
            <textarea className="form-control note-min" placeholder="Enter your feedback" {...register("feedback", { required: true })} />
            {errors.feedback && <span className="validation_error">Feedback is required</span>}
          </div>
          <div className="col-md-12 mt-4">
            <button type="submit" className="btn btn btn-primary ">Submit</button></div>
        </div>
      </form>
    </div>
  );
}



export default ContactForm;

